import Dashboard from "layouts/dashboard";
import Tracker from "layouts/tracker";
import aiapptool from "layouts/aiapptool";
import aicode from "layouts/aiapptool/aicode";
import aicodeexpander from "layouts/aiapptool/aicodeexpander";
import Canva from "layouts/canva";
import Projects from "layouts/projects";
import Flippa from "layouts/flippa";
import Affiliate from "layouts/Affiliate";
import socialtoolsTemplate from "layouts/socialtools";
import Iconsize from "layouts/iconsize";
import Billing from "layouts/billing";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Logout from "layouts/authentication/logout";
import Funnel from "layouts/authentication/funnel";
import Blog1 from "layouts/authentication/blog/blog1";
import Blog2 from "layouts/authentication/blog/blog2";
import Blog3 from "layouts/authentication/blog/blog3";
import Blog4 from "layouts/authentication/blog/blog4";
import Blog5 from "layouts/authentication/blog/blog5";
import Blog6 from "layouts/authentication/blog/blog6";
import Apps from "layouts/apps";
import { IoRocketSharp } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { IoDuplicate } from "react-icons/io5";
import { IoFileTrayFull } from "react-icons/io5";
import { IoAnalyticsSharp } from "react-icons/io5";
import { IoGlobe } from "react-icons/io5";
import { IoBrush } from "react-icons/io5";
import { FaAppStoreIos } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { MdDesignServices } from "react-icons/md";
import  Home  from "layouts/authentication/home";
import  Pricing  from "layouts/authentication/pricing";
import  Income  from "layouts/authentication/income-calc";
import  AffiliatePage  from "layouts/authentication/affiliate";
import  AppStoreFreeGen  from "layouts/authentication/AppStoreFreeGen";
import  AiAppBreakdown  from "layouts/authentication/AiAppBreakdown";
import  ProductsPage  from "layouts/authentication/ProductsPage";
import  Seminar  from "layouts/authentication/Seminar";

const routes = [

  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Ai App Tool",
    key: "AI",
    route: "/ai-app-tool",
    icon: <IoRocketSharp size="15px" color="inherit" />,
    component: aiapptool,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    route: "/projects",
    icon: <IoFileTrayFull size="15px" color="inherit" />,
    component: Projects,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Creation Tracker",
    key: "tracker",
    route: "/tracker",
    icon: <IoAnalyticsSharp size="15px" color="inherit" />,
    component: Tracker,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Ready Apps",
    key: "apps",
    route: "/apps",
    icon: <FaAppStoreIos size="15px" color="inherit" />,
    component: Apps,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Canva Templates",
    key: "canva",
    route: "/canva",
    icon: <IoBrush size="15px" color="inherit" />,
    component: Canva,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "App Store Design Lab",
    key: "iconsize",
    route: "/iconsize",
    icon: <MdDesignServices size="15px" color="inherit" />,
    component: Iconsize,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Chrome Extensions",
    key: "chrome",
    route: "/chrome",
    icon: <IoGlobe size="15px" color="inherit" />,
    component: socialtoolsTemplate,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Flippa",
    key: "flippa",
    route: "/flippa",
    icon: <IoDuplicate size="15px" color="inherit" />,
    component: Flippa,
    noCollapse: true,
  },
  
  {
    type: "collapse",
    name: "Get Paid",
    key: "affiliate",
    route: "/affiliate",
    icon: <FaMoneyBillWave size="15px" color="inherit" />,
    component: Affiliate,
    noCollapse: true,
  },

  {
    route: "/billing",
    component: Billing,
  },

  {
    route: "/aicode",
    component: aicode,
    noCollapse: true,
  },

  {
    route: "/aicodeexpander",
    component: aicodeexpander,
    noCollapse: true,
  },

  {
    route: "/authentication/sign-in",
    component: SignIn,
  },
  
  {
    route: "/authentication/sign-up",
    component: SignUp,
  },
  
  {
    route: "/Home",
    component: Home,
  },

  {
    route: "/affiliate-page",
    component: AffiliatePage,
  },

  {
    route: "/app-store-icon-size-generator",
    component: AppStoreFreeGen,
  },

  {
    route: "/ai-app-idea-breakdown-tool",
    component: AiAppBreakdown,
  },

  {
    route: "/products-page",
    component: ProductsPage,
  },

  {
    route: "/pricingpage",
    component: Pricing,
  },

  {
    route: "/seminar",
    component: Seminar,
  },

  {
    route: "/income-calculator",
    component: Income,
  },

  {
    route: "/bubblevsflutterflow",
    component: Blog1,
  },

  {
    route: "/goodbarbervsglide",
    component: Blog2,
  },

  {
    route: "/buildfirevsappmakr",
    component: Blog3,
  },

  {
    route: "/kodularvsthunkable",
    component: Blog4,
  },
  
  {
    route: "/adalovsbravostudio",
    component: Blog5,
  },

  {
    route: "/outsystemsvsmendix",
    component: Blog6,
  },

  {
    route: "/Funnel",
    component: Funnel,
  },

  {
    route: "/authentication/logout",
    component: Logout,
  },
  
];
export default routes;
