import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { Link, useNavigate } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/BasicLayout";
import borders from "assets/theme/base/borders";
import 'rc-slider/assets/index.css';
import { backdropClasses, useMediaQuery } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import bgDash4 from "assets/images/bg4.svg";
import longlogoblack from "assets/images/codegrowlong-black.png";
import minicolourbg from "assets/images/minicolourbg.svg";
import { IoMdMenu } from "react-icons/io";
import { Drawer } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu, MenuItem } from "@mui/material";
import { TbAffiliateFilled } from "react-icons/tb";
import { FaMoneyBillWave } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { IoIosAppstore } from "react-icons/io";
import { GiArtificialIntelligence } from "react-icons/gi";
import ai1 from "assets/images/ai1.png";
import CountdownTimer from "./CountdownTimer";
import ThirdWhiteBubble from "./Content";
import monthly from "assets/images/monthly.svg"; // Replace with your background image
import yearly from "assets/images/yearly.svg"; // Replace with your background image
import growth from "assets/images/growth.svg"; // Replace with your background image
import { Icon } from '@mui/material'; // Assuming you're using Material UI icons
import { Code, Cloud, GitHub, Web, Api, Lock, BookIcon, Book } from '@mui/icons-material'; // Importing Material UI icons
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AdUnitsIcon from '@mui/icons-material/AdUnits';

function SignIn() {

  useEffect(() => {
    // Ensure the window object is available before running the animation
    if (typeof window !== "undefined") {
      // GSAP Animation for all elements with .pop-in
      gsap.utils.toArray(".pop-in").forEach((element) => {
        gsap.from(element, {
          opacity: 0,
          y: 50,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: element, // Use the individual element as the trigger
            start: "top 95%",
            toggleActions: "play none none reverse",
          },
        });
      });

      // Inject Google Tag Manager script
      const gtagScript = document.createElement("script");
      gtagScript.async = true;
      gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-16880834463";
      document.head.appendChild(gtagScript);

      // Initialize Google Tag
      const gtagInitScript = document.createElement("script");
      gtagInitScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16880834463');
      `;
      document.head.appendChild(gtagInitScript);

    }
  }, []);

  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const [openQuestion, setOpenQuestion] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isYearly, setIsYearly] = useState(true);


  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 200); // Short delay to prevent accidental closing
  };



  // Handle burger menu button click
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "Do I need coding experience?",
      answer:
        "Not at all! Our live class is designed for complete beginners. We'll show you how to use AI tools to build your app, even if you've never written a line of code before."
    },
    {
      question: "What will I learn in the live class?",
      answer:
        "You'll learn how to use AI to build a full-stack web application. We'll cover everything from initial setup to deployment, including how to use AI for coding, debugging, and problem-solving."
    },
    {
      question: "What if I can't attend live?",
      answer:
        "While attending live allows you to ask questions and interact, we understand that not everyone can make it. All classes are recorded and available to subscribers immediately after the session."
    },
    {
      question: "What tools will we be using?",
      answer:
        "We'll be using modern development tools including Next.js, Tailwind CSS, and various AI assistants. Don't worry if these sound unfamiliar - we'll explain everything step by step."
    },


  ];

  function navigateToVideoSection() {
    const element = document.getElementById("demoSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  function navigateToSignIn() {
    // TikTok PII Identification (if applicable)
    ttq.identify({
      "email": "<hashed_email_address>", // Replace with SHA-256 hashed email
      "phone_number": "<hashed_phone_number>", // Replace with SHA-256 hashed phone number
      "external_id": "<hashed_external_id>" // Replace with SHA-256 hashed external ID
    });

    // TikTok Event Tracking
    ttq.track('ViewContent', {
      page_section: "Sign-In Page"
    });

    // Navigation logic
    navigate("/authentication/sign-up");
  }

  function navigateToSignIn2() {
    // TikTok PII Identification (if applicable)
    ttq.identify({
      "email": "<hashed_email_address>", // Replace with SHA-256 hashed email
      "phone_number": "<hashed_phone_number>", // Replace with SHA-256 hashed phone number
      "external_id": "<hashed_external_id>" // Replace with SHA-256 hashed external ID
    });

    // TikTok Event Tracking
    ttq.track('ViewContent', {
      page_section: "Sign-In Page"
    });

    // Navigation logic
    navigate("/authentication/sign-in");
  }

  function navigateToPricing() {
    navigate("/pricingPage");
  }


  function navigateToHome() {
    navigate("/home");
  }

  function navigateToCalculator() {
    navigate("/income-calculator");
  }

  function navigateToAffiliate() {
    navigate("/affiliate-page");
  }

  function navigateToIcons() {
    navigate("/app-store-icon-size-generator");
  }
  function navigateToIdea() {
    navigate("/ai-app-idea-breakdown-tool");
  }
  function navigateToProducts() {
    navigate("/products-page");
  }

  const timeoutRef = useRef(null);

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);

    }, 500); // Delay before closing (300ms) (300ms)
  };
  const handleMouseLeave2 = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen2(false);
    }, 500); // Delay before closing (300ms) (300ms)
  };
  return (
    <><CoverLayout >

      {/* top section start */}
      <VuiBox
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "white",
          minHeight: "100vh",
          marginBottom: "-20px",
          backgroundImage: `url(${bgDash4})`, // Background image for the landing page
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "0",
        }}
      >
        {/* Wrapper for the images and SVG */}
        <VuiBox
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between", // Push images to the sides
            alignItems: "center",
            position: "relative", // Allows absolute positioning inside
            padding: "0 50px", // Adjust space from edges
          }}
        >




        </VuiBox>


        {/* top menu desktop*/}
        <VuiBox
          display={isMobile ? "none" : "flex"}
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "95%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"

          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              onClick={() => navigateToHome()}


              src={longlogoblack} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "17vw" : "6.5vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          <VuiBox display="flex" gap="0px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="outline"
              size="medium"
              onClick={() => navigateToProducts()}
              sx={{

                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Products
            </VuiButton>




            <VuiBox
              sx={{
                position: "relative",
                display: "inline-block",
              }}
              onMouseEnter={() => { setIsOpen(true), setIsOpen2(false) }}
            >
              {/* Button */}
              <VuiButton
                variant="outline"
                size="medium"
                sx={{
                  color: "#fff",
                  fontSize: "1.3vw",
                  padding: "10px 20px",
                  borderRadius: "55px",
                  transition: "color 0.3s ease",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#fe3737",
                  },
                }}
              >
                Free Tools <ArrowDropDownIcon />
              </VuiButton>

              {/* Dropdown Menu */}
              {isOpen && (
                <VuiBox
                  onMouseLeave={handleMouseLeave}

                  sx={{
                    zIndex: '150',
                    position: "absolute",
                    top: "120%",
                    left: '-20vw',
                    width: "70vw",
                    background: "#1a1a1a",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: '40px'
                  }}
                >
                  {/* Two Buttons on the Left */}
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '12vw', }}>
                    <VuiButton
                      variant="contained"
                      onClick={() => navigateToIcons()}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <IoIosAppstore size={isMobile ? 24 : 80} color="#fe3737" />
                      App Store Icon Size Generator
                    </VuiButton>

                    <VuiButton
                      variant="contained"
                      onClick={() => navigateToIdea()}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <GiArtificialIntelligence size={isMobile ? 24 : 80} color="#fe3737" />
                      AI App Idea & Breakdown Tool
                    </VuiButton>
                  </div>

                  <VuiBox
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="column"
                    alignItems={isMobile ? "start" : "start"}
                    // maxWidth="800px"
                    width={isMobile ? "100%" : "30%"}
                    padding="20px"
                    height="16vw"

                    borderRadius={borders.borderRadius.md}
                    className="pop-in"

                  >


                    <div>


                      <VuiTypography
                        style={{
                          component: "h1",
                          textAlign: "start",
                          fontSize: isMobile ? "6.5vw" : "1.2vw",
                          fontWeight: "bold",
                          color: "white",
                          marginBottom: isMobile ? "10%" : "0px",

                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                        }}
                        className="pop-in"

                      >
                        Find & Create Your Perfect App
                      </VuiTypography>

                    </div>

                    <div>

                      <VuiTypography
                        style={{
                          component: "h2",
                          textAlign: isMobile ? "start" : "start",
                          fontSize: isMobile ? "4.5vw" : "1vw",
                          fontWeight: "bold",
                          color: "#69696a",
                          marginBottom: isMobile ? "10%" : "0px",
                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here

                        }}
                        className="pop-in"

                      >
                        CodeGrow turns your ideas into fully montiesed IOS apps ready to be released to the App Store                      </VuiTypography>



                    </div>

                    <div>
                      <VuiButton
                        aligncontent="start"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={navigateToSignIn2}
                        style={{
                          backgroundColor: 'transparent',
                          border: " 2px solid #FE3737",
                          color: " #FE3737",
                          fontSize: isMobile ? "3.5vw" : "1vw",
                          padding: "10px 20px",
                          borderRadius: '55px',

                        }}
                      >
                        Start For Free
                      </VuiButton>
                    </div>

                  </VuiBox>

                  {/* Image on the Right */}
                  <img
                    src={ai1}
                    alt="Tool Preview"
                    style={{ width: "20vw", borderRadius: "5px" }}
                  />
                </VuiBox>
              )}
            </VuiBox>



            <VuiBox
              sx={{
                position: "relative",
                display: "inline-block",
              }}
              onMouseEnter={() => { setIsOpen2(true), setIsOpen(false) }}
            >
              {/* Button */}
              <VuiButton
                variant="outline"
                size="medium"

                sx={{
                  color: "#fff",
                  fontSize: "1.3vw",
                  padding: "10px 20px",
                  borderRadius: "55px",
                  transition: "color 0.3s ease",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#fe3737",
                  },
                }}
              >
                Resources <ArrowDropDownIcon />
              </VuiButton>

              {/* Dropdown Menu */}
              {isOpen2 && (
                <VuiBox
                  onMouseLeave={handleMouseLeave2}

                  sx={{
                    zIndex: '150',
                    position: "absolute",
                    top: "120%",
                    left: '-20vw',
                    width: "70vw",
                    background: "#1a1a1a",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: '40px'
                  }}
                >
                  {/* Two Buttons on the Left */}
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '12vw', }}>
                    <VuiButton
                      variant="contained"
                      onClick={navigateToCalculator}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <FaMoneyBillWave size={isMobile ? 24 : 40} color="#fe3737" />
                      Income Calculator
                    </VuiButton>

                    <VuiButton
                      variant="contained"
                      onClick={navigateToAffiliate}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <FaCoins size={isMobile ? 24 : 40} color="#fe3737" />

                      Affiliate Program
                    </VuiButton>
                  </div>

                  <VuiBox
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="column"
                    alignItems={isMobile ? "start" : "start"}
                    // maxWidth="800px"
                    width={isMobile ? "100%" : "30%"}
                    padding="20px"
                    height="16vw"

                    borderRadius={borders.borderRadius.md}
                    className="pop-in"

                  >


                    <div>


                      <VuiTypography
                        style={{
                          component: "h1",
                          textAlign: "start",
                          fontSize: isMobile ? "6.5vw" : "1.2vw",
                          fontWeight: "bold",
                          color: "white",
                          marginBottom: isMobile ? "10%" : "0px",

                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                        }}
                        className="pop-in"

                      >
                        Find & Create Your Perfect App
                      </VuiTypography>

                    </div>

                    <div>

                      <VuiTypography
                        style={{
                          component: "h2",
                          textAlign: isMobile ? "start" : "start",
                          fontSize: isMobile ? "4.5vw" : "1vw",
                          fontWeight: "bold",
                          color: "#69696a",
                          marginBottom: isMobile ? "10%" : "0px",
                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here

                        }}
                        className="pop-in"

                      >
                        CodeGrow turns your ideas into fully montiesed IOS apps ready to be released to the App Store                      </VuiTypography>



                    </div>

                    <div>
                      <VuiButton
                        aligncontent="start"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={navigateToSignIn2}
                        style={{
                          backgroundColor: 'transparent',
                          border: " 2px solid #FE3737",
                          color: " #FE3737",
                          fontSize: isMobile ? "3.5vw" : "1vw",
                          padding: "10px 20px",
                          borderRadius: '55px',

                        }}
                      >
                        Start For Free
                      </VuiButton>
                    </div>

                  </VuiBox>

                  {/* Image on the Right */}
                  <img
                    src={ai1}
                    alt="Tool Preview"
                    style={{ width: "20vw", borderRadius: "5px" }}
                  />
                </VuiBox>
              )}
            </VuiBox>


            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ bgcolor: "white", padding: "0px !important", }}
              MenuListProps={{
                onMouseEnter: () => clearTimeout(),
                onMouseLeave: handleClose
              }}
            >
              <MenuItem
                onClick={navigateToCalculator}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: '10px',

                  border: "2px solid white",
                  backgroundColor: "white !important", // Force white bg
                  color: "black !important", // Force black text
                  "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                }}
              >
                <FaMoneyBillWave size={isMobile ? 24 : 32} color="#fe3737" />
                Income Calculator
              </MenuItem>

              <MenuItem
                onClick={navigateToAffiliate}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: '0px',
                  border: "2px solid white",
                  backgroundColor: "white !important", // Force white bg
                  color: "black !important", // Force black text
                  "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                }}
              >
                <TbAffiliateFilled size={isMobile ? 24 : 32} color="#fe3737" />
                Affiliate
              </MenuItem>
            </Menu>


            <VuiButton
              variant="outline"
              size="medium"
              onClick={() => navigateToPricing()}
              sx={{

                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Pricing
            </VuiButton>
          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="outline"
              size="medium"
              onClick={() => navigateToSignIn2()}
              sx={{

                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Log in
            </VuiButton>

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              sx={{
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                display: isMobile ? "none" : "block",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Get Started
            </VuiButton>
            <VuiButton
              variant="contained"
              size="medium"
              onClick={navigateToVideoSection}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important", // Ensures the text color changes
                  backgroundColor: "#fe3737",
                },
              }}
            >
              See Demo
            </VuiButton>
          </VuiBox>
        </VuiBox>
        {/* top menu desktop end */}

        {/* Top menu mobile */}
        <VuiBox
          display={isMobile ? "flex" : "none"}
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "95%"}
          marginTop="0px"
          padding="20px"
          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              onClick={() => navigateToHome()}


              src={longlogoblack} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "24vw" : "6.5vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          {/* Burger Button */}
          <VuiBox display="flex" flexDirection="column" gap="0px" style={{ marginBottom: '-20px' }}>
            <VuiButton
              variant="outlined"
              onClick={toggleDrawer}
              sx={{
                color: "#fff",
                fontSize: isMobile ? "4vw" : "2vw",
                padding: "10px 20px",
                borderRadius: "10%",
                backgroundColor: "black", // Styling for burger button
                transition: "background-color 0.3s ease",
                border: '0px solid black',
                "&:hover": {
                  backgroundColor: "#fe3737", // Hover effect for burger button
                },
              }}
            >
              <IoMdMenu size="30px" />
            </VuiButton>
          </VuiBox>
        </VuiBox>

        {/* Drawer Popup when Burger Button is clicked */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer}

          sx={{
            width: "30%", // Adjust the width as needed
            padding: "20px",
            bgcolor: "black",
            height: "auto", // Will adjust dynamically based on the buttons height
            overflowY: "auto",
          }}
        >
          {/* Close Button */}
          {/* Close Button */}
          <VuiBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            position="absolute"
            top="20px"
            right="20px"
          >
            <VuiButton
              onClick={toggleDrawer}

              sx={{
                color: "#000", // Black text color
                backgroundColor: "transparent", // No background
                fontSize: "2vw",
                padding: "0",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              ✕
            </VuiButton>
          </VuiBox>

          <VuiBox display="flex" flexDirection="column" gap="20px" marginTop="60px" padding="20px" >
            {/* The buttons inside the popup */}
            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToHome()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              Home
            </VuiButton>

            {/* The buttons inside the popup */}
            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToProducts()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              Products
            </VuiButton>

            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToIcons()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              App Icon Size Generator
            </VuiButton>

            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToIdea()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              AI Developer
            </VuiButton>
            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToCalculator()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              Income Calculator
            </VuiButton>

            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToAffiliate()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              Affiliate Program
            </VuiButton>

            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToPricing()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              Pricing
            </VuiButton>

            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToSignIn()}
              sx={{
                backgroundColor: "#fe3737",
                color: "white",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "#fe3737 !important",
                  backgroundColor: "whites",
                },
              }}
            >
              Get Started
            </VuiButton>

            <VuiButton
              variant="contained"
              size="medium"
              onClick={() => navigateToSignIn2()}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: "3.5vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important",
                  backgroundColor: "#fe3737",
                },
              }}
            >
              Log in
            </VuiButton>


          </VuiBox>
        </Drawer>
        {/* End of top menu mobile */}


        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop={isMobile ? "12%" : "4%"}
          style={{ textAlign: "center" }}
          width="80vw"
        >
          <div>

            <VuiTypography
              style={{
                component: "h1",
                textAlign: "center",
                fontSize: isMobile ? "7vw" : "5.3vw",
                fontWeight: "bold",
                color: "white",
                marginBottom: "0px",
                lineHeight: isMobile ? "1.5" : "1.2" // Adjust line spacing here
              }}
              className="pop-in"

            >
              Build IOS Apps Using AI
            </VuiTypography>



          </div>
          <br />

          <div style={{ width: isMobile ? "100%" : "70%" }}>
            <VuiTypography
              style={{
                component: "h2",
                textAlign: "center",
                fontSize: isMobile ? "4.5vw" : "1.6vw",
                fontWeight: "bold",
                color: "#ffffff95",
                marginBottom: isMobile ? "15px" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
              className="pop-in"

            >
              Learn how to build real apps with AI in our weekly live class - <bold style={{ color: 'white' }}>no coding experience needed</bold>. From idea to a fully monetised IOS app.

            </VuiTypography>
          </div>
          <br />

          <div style={{ width: isMobile ? "100%" : "70%" }}>
            <VuiTypography
              style={{
                component: "h2",
                textAlign: "center",
                fontSize: isMobile ? "4.5vw" : "1.9vw",
                fontWeight: "bold",
                color: "#ffffff",
                marginBottom: isMobile ? "15px" : "10px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
              className="pop-in"

            >
              <i>
                Next Live Class Friday at 7:00 PM UK Time

              </i>
            </VuiTypography>
          </div>

          <CountdownTimer className="pop-in" />

          <br />

          <VuiBox display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
            <VuiTypography
              style={{
                component: "h2",
                textAlign: "center",
                fontSize: isMobile ? "4.5vw" : "1.3vw",
                fontWeight: "bold",
                color: "#ffffff",
                marginBottom: isMobile ? "25px" : "20px",
                lineHeight: isMobile ? "1.5" : "1.2" // Adjust line spacing here
              }}
            // className="pop-in"

            >

              Free A.I. prompting templates
              if you show up to live class

            </VuiTypography>

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSebz1h6iDyfZxlOoTt_VlY1P1EeIWj6muneEA3g5VsLklioVw/viewform", "_blank")}
              style={{
                backgroundColor: "#ffffff",
                color: "black",
                fontSize: isMobile ? "4.5vw" : "1.7vw",
                padding: "12px 45px",
                borderRadius: '55px',
                width: isMobile ? "100%" : "50%",
                component: "h3",
              }}
            // className="pop-in"

            >
              Join the Live Class

            </VuiButton>
            <VuiTypography
              variant="body2"
              style={{ marginTop: '8px', textAlign: 'center', component: "h4", color: "#FFFFFF90" }}


            >
              No credit card required
            </VuiTypography>
          </VuiBox>

          <br />
          <br />

        </VuiBox>


      </VuiBox>
      {/* top section end */}

      {/* second section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "white", // Fading effect

          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "start" : "center"}
          width={isMobile ? "100%" : "97%"}
          // maxWidth="800px"
          marginTop="-70px"
          padding="20px"
          style={{
            // background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
          }}
          borderRadius={borders.borderRadius.md}
        >

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px', width: '100vw' }}
          >


            <ThirdWhiteBubble isMobile={isMobile} />

          </VuiBox>


        </VuiBox>
        {/* top menu end */}

        <br />

        {/* How does it all work? */}
        <VuiBox
          display={"flex"}
          flexDirection="column"

          justifyContent={"space-between"}
          alignItems="center"
          marginLeft={isMobile ? "0px" : '5%'}
          width={isMobile ? "90%" : "90%"}
          // maxWidth="800px"

          height={" 100%"}
          padding="0px"

          borderRadius="25px"
          className="pop-in"
        >


          <VuiTypography
            style={{
              component: "h1",
              textAlign: "center",
              fontSize: isMobile ? "6.9vw" : "5vw",
              fontWeight: "bold",
              color: "black",
              marginTop: "15px",
              lineHeight: isMobile ? "1.3" : "1.4" // Adjust line spacing here
            }}
          // className="pop-in"

          >
            Everything You Need For Free

          </VuiTypography>


          <VuiTypography
            style={{
              marginTop: isMobile ? "10%" : "0px",
              component: "h2",
              textAlign: "center",
              fontSize: isMobile ? "5vw" : "1.6vw",
              fontWeight: "bold",
              color: "#4B4B4B",
              marginBottom: isMobile ? "6%" : "0px",
              lineHeight: isMobile ? "1.1" : "1.9" // Adjust line spacing here
            }}
          // className="pop-in"

          >

            Become a member of CodeGrow for free and learn to build your first MVP using Ai.




          </VuiTypography>
          <br />



          <VuiBox
            display={"flex"}
            flexDirection={isMobile ? "column" : "row"}

            justifyContent={"space-between"}
            alignItems="center"

            width={isMobile ? "80%" : "100%"}
            marginLeft={isMobile? "0%":"-5.2%"}

            // maxWidth="800px"

            height={" 100%"}
            padding="0px"

            borderRadius="25px"
            gap="40px"
          >

            {/* start */}

            <VuiBox
              width={isMobile ? "100%" : "18.2%"}
              height={isMobile ? "100%" : "18vw"}
              padding="0"
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Top Section with Background Image */}
              <VuiBox
                style={{
                  height: isMobile ? "30%" : "6vw",
                  width: "100%",
                  backgroundImage: `url(${bgDash4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "relative",
                  padding: "10px",
                }}
              >
                {/* Cloud Icon Overlay (Fixed Visibility) */}
                <Icon
                  style={{
                    width: isMobile ? "12vw" : "4vw", // Adjust width to make it proportional
                    height: isMobile ? "12vw" : "4vw", // Maintain aspect ratio
                    transform: "scale(1.2)", // Prevent excessive scaling
                    color: "white",
                    background: "#00000000",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Book style={{ color: "white", width: "100%", height: "100%" }} />
                </Icon>
              </VuiBox>

              {/* Content Section */}
              <VuiBox
                style={{
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <VuiTypography
                  style={{
                    fontSize: isMobile ? "5.8vw" : "1.3vw",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  Blog Content
                </VuiTypography>

                <VuiTypography
                  style={{
                    fontSize: isMobile ? "4.8vw" : "1vw",
                    fontWeight: "bold",
                    color: "#4B4B4B",
                  }}
                >
                  Detailed AI coding tutorials in written format, perfect for step-by-step learning.
                </VuiTypography>
              </VuiBox>
            </VuiBox>


            <VuiBox
              width={isMobile ? "100%" : "18.2%"}
              height={isMobile ? "100%" : "20vw"}
              padding="0"
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Top Section with Background Image */}
              <VuiBox
                style={{
                  height: isMobile ? "30%" : "6vw",
                  width: "100%",
                  backgroundImage: `url(${bgDash4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "relative",
                  padding: "10px",
                }}
              >
                {/* Cloud Icon Overlay (Fixed Visibility) */}
                <Icon
                  style={{
                    width: isMobile ? "12vw" : "4vw", // Adjust width to make it proportional
                    height: isMobile ? "12vw" : "4vw", // Maintain aspect ratio
                    transform: "scale(1.2)", // Prevent excessive scaling
                    color: "white",
                    background: "#00000000",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <AutoFixHighIcon style={{ color: "white", width: "100%", height: "100%" }} />
                </Icon>
              </VuiBox>
              
              <VuiBox
                style={{
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <VuiTypography
                  style={{
                    fontSize: isMobile ? "5.8vw" : "1.3vw",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >  AI Tools Suite
                </VuiTypography>
                <br />
                <VuiTypography style={{ component: "h3", fontSize: isMobile ? "4.8vw" : "1vw", fontWeight: "bold", color: "#4B4B4B", }}>
                  Access our collection of AI tools designed specifically for modern app development.
                </VuiTypography>
              </VuiBox>
              <br />

            </VuiBox>

            <VuiBox
              width={isMobile ? "100%" : "18.2%"}
              height={isMobile ? "100%" : "22vw"}
              padding="0"
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Top Section with Background Image */}
              <VuiBox
                style={{
                  height: isMobile ? "30%" : "6vw",
                  width: "100%",
                  backgroundImage: `url(${bgDash4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "relative",
                  padding: "10px",
                }}
              >
                {/* Cloud Icon Overlay (Fixed Visibility) */}
                <Icon
                  style={{
                    width: isMobile ? "12vw" : "4vw", // Adjust width to make it proportional
                    height: isMobile ? "12vw" : "4vw", // Maintain aspect ratio
                    transform: "scale(1.2)", // Prevent excessive scaling
                    color: "white",
                    background: "#00000000",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Code style={{ color: "white", width: "100%", height: "100%" }} />
                </Icon>
              </VuiBox>
              
              <VuiBox
                style={{
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <VuiTypography
                  style={{
                    fontSize: isMobile ? "5.8vw" : "1.3vw",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >  Ready-to-Launch Apps
                </VuiTypography>
                <br />
                <VuiTypography style={{ component: "h3", fontSize: isMobile ? "4.8vw" : "1vw", fontWeight: "bold", color: "#4B4B4B", }}>
                  Access complete IOS applications with commercial licenses. Build your empire today.
                </VuiTypography>
              </VuiBox>
              <br />

            </VuiBox>

            <VuiBox
              width={isMobile ? "100%" : "18.2%"}
              height={isMobile ? "100%" : "20vw"}
              padding="0"
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Top Section with Background Image */}
              <VuiBox
                style={{
                  height: isMobile ? "30%" : "6vw",
                  width: "100%",
                  backgroundImage: `url(${bgDash4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "relative",
                  padding: "10px",
                }}
              >
                {/* Cloud Icon Overlay (Fixed Visibility) */}
                <Icon
                  style={{
                    width: isMobile ? "12vw" : "4vw", // Adjust width to make it proportional
                    height: isMobile ? "12vw" : "4vw", // Maintain aspect ratio
                    transform: "scale(1.2)", // Prevent excessive scaling
                    color: "white",
                    background: "#00000000",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <AdUnitsIcon style={{ color: "white", width: "100%", height: "100%" }} />
                </Icon>
              </VuiBox>
              
              <VuiBox
                style={{
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <VuiTypography
                  style={{
                    fontSize: isMobile ? "5.8vw" : "1.3vw",
                    fontWeight: "bold",
                    color: "black",
                  }}
                > 
 Marketing Suite
                </VuiTypography>
                <br />
                <VuiTypography style={{ component: "h3", fontSize: isMobile ? "4.8vw" : "1vw", fontWeight: "bold", color: "#4B4B4B", }}>
                  Access our collection of Ad Creatives designed specifically for modern app growth.
                </VuiTypography>
              </VuiBox>
              <br />

            </VuiBox>

            <VuiBox
              width={isMobile ? "100%" : "18.2%"}
              height={isMobile ? "100%" : "18vw"}
              padding="0"
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                borderRadius: "25px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Top Section with Background Image */}
              <VuiBox
                style={{
                  height: isMobile ? "30%" : "6vw",
                  width: "100%",
                  backgroundImage: `url(${bgDash4})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "relative",
                  padding: "10px",
                }}
              >
                {/* Cloud Icon Overlay (Fixed Visibility) */}
                <Icon
                  style={{
                    width: isMobile ? "12vw" : "4vw", // Adjust width to make it proportional
                    height: isMobile ? "12vw" : "4vw", // Maintain aspect ratio
                    transform: "scale(1.2)", // Prevent excessive scaling
                    color: "white",
                    background: "#00000000",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <MonetizationOnIcon style={{ color: "white", width: "100%", height: "100%" }} />
                </Icon>
              </VuiBox>
              
              <VuiBox
                style={{
                  padding: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <VuiTypography
                  style={{
                    fontSize: isMobile ? "5.8vw" : "1.3vw",
                    fontWeight: "bold",
                    color: "black",
                  }}
                > 
           Step By Step Sale
                </VuiTypography>
                <br />
                <VuiTypography style={{ component: "h3", fontSize: isMobile ? "4.8vw" : "1vw", fontWeight: "bold", color: "#4B4B4B", }}>
                  Learn how to sell your AI IOS apps, using our step by step portfolio tools.
                </VuiTypography>
              </VuiBox>
              <br />

            </VuiBox>

            {/* end */}
          </VuiBox>
        </VuiBox>
        {/* How does it all work? end*/}
        <br />
        <br />
      </VuiBox>

      {/* second section end */}


      {/* fourth section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: isMobile
            ? "linear-gradient(to bottom, black, #020414)"
            : "linear-gradient(to bottom, black, #020414)",
          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* FAQ */}
        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "0px 20px",
            background: "black",

            // background: "linear-gradient(to top, #030515 0%, #7f8c8d 70%, #f7f8fb 100%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <VuiTypography
            style={{
              component: "h2",
              fontSize: "48px",
              color: "#FC3837",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
              marginBottom: '50px'
            }}
            className="pop-in"

          >
            <b style={{ fontSize: isMobile ? "5.8vw" : "3.9vw", color: "white" }}>
              Common Questions About the Live Class


            </b>
          </VuiTypography>

          {/* FAQ list */}
          <VuiBox
            style={{
              width: isMobile ? "90%" : "80%",
              maxWidth: "1200px",
              marginTop: "30px",
            }}
          >
            {faqData.map((faq, index) => (
              <VuiBox
                key={index}
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "white",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleQuestion(index)}
              >
                {/* Question */}
                <VuiTypography
                  style={{
                    color: "black",
                    fontSize: isMobile ? "4vw" : "1.5vw",
                  }}
                >
                  {faq.question}
                </VuiTypography>

                {/* Answer */}
                {openQuestion === index && (
                  <VuiTypography
                    style={{
                      marginTop: "10px",
                      fontSize: isMobile ? "4vw" : "1.2vw",
                      color: "black",
                      transition: "max-height 0.3s ease-in-out",
                      overflow: "hidden",
                    }}
                  >
                    {faq.answer}
                  </VuiTypography>
                )}
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
        {/* triple section end */}

        {/* How does it all work? */}
        <VuiBox
          display={"flex"}
          flexDirection="column"

          justifyContent={"space-between"}
          alignItems="center"
          marginLeft={isMobile ? "0px" : '0%'}
          width={isMobile ? "100%" : "100%"}
          // maxWidth="800px"

          height={" 100%"}
          padding="0px"

          borderRadius="25px"
          className="pop-in"

        >


          <br />

          <VuiTypography
            style={{
              component: "h1",
              textAlign: "center",
              fontSize: isMobile ? "6.5vw" : "5vw",
              fontWeight: "bold",
              color: "white",
              marginTop: "30px",
              lineHeight: isMobile ? "1.1" : "1.4" // Adjust line spacing here
            }}
          // className="pop-in"

          >
            Ready to start building?

          </VuiTypography>
          <br />

          <VuiButton
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSebz1h6iDyfZxlOoTt_VlY1P1EeIWj6muneEA3g5VsLklioVw/viewform", "_blank")}
            style={{
              color: "black",
              backgroundColor: "white",
              fontSize: isMobile ? "4.5vw" : "1.7vw",
              padding: "12px 45px",
              marginTop: "15px",
              borderRadius: '55px',
              width: isMobile ? "70%" : "50%",
            }}
          >
            Join the Live Class
          </VuiButton>
          <br />
          <VuiTypography
            variant="body2"
            style={{ marginTop: '1px', marginBottom: '20px', textAlign: 'center', component: "h4", color: "#ffffff90" }}


          >
            No credit card required
          </VuiTypography>
          <br />





        </VuiBox>
        {/* How does it all work? end*/}

      </VuiBox>
      {/* fourth section end */}



      <br />



    </CoverLayout>
    </>
  );
}

export default SignIn;
