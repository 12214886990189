import Grid from "@mui/material/Grid";
import { Card, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Typography from "@mui/material/Typography";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import FreeLayout from "examples/LayoutContainers/FreeLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import SatisfactionRate from "layouts/dashboard/components/SatisfactionRate";
import { IoRocketSharp, IoGlobe, IoWallet, IoListCircleSharp } from "react-icons/io5";
import LineChart from "examples/Charts/LineCharts/LineChart";
import { lineChartOptionsDashboard } from "layouts/dashboard/data/lineChartOptions";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import GuideTable from "./guide";
import VuiButton from "components/VuiButton";
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import { MdDesignServices } from "react-icons/md";
import PricingTable from "components/PricingTable";
import VuiInput from "components/VuiInput";
import IconButton from "@mui/material/IconButton";
import { ContentCopy, CheckCircle } from "@mui/icons-material";

function Dashboard() {
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const [exportableData, setExportableData] = useState([{ name: "Loading...", data: [0] }]);
  const db = getFirestore();
  const auth = getAuth();
  const [currentMonthRevenue, setCurrentMonthRevenue] = useState(0);
  const [previousMonthRevenue, setPreviousMonthRevenue] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);
  const [previousMonthName, setPreviousMonthName] = useState('');
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December
  const previousMonth = (currentMonth === 0) ? 11 : currentMonth - 1; // Wrap around for January
  let totalCurrentMonthRevenue = 0;
  let totalPreviousMonthRevenue = 0;
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loadingPrice, setLoadingPrice] = useState(true);
  const [appsData, setAppsData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [barChartDataDashboard, setBarChartDataDashboard] = useState([]);
  const [buttonColor, setButtonColor] = useState('primary');
  const [showPopup, setShowPopup] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);


  const [copied, setCopied] = useState(false); // State to track copy status

  const handleCopy = () => {
    navigator.clipboard.writeText('CODEGROW20');


  };
  // Function to fetch the total active users
  const fetchTotalPrice = async () => {
    setLoadingPrice(true);
    let totalPriceCount = 0;
    const user = auth.currentUser;

    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data().liveProjects || [];
        console.log(userData);

        // Sum the activeUsers for each project, ensuring it's treated as a number
        userData.forEach((project) => {
          totalPriceCount += Number(project.listingPrice) || 0; // Ensure activeUsers is converted to a number
        });
      }

      setTotalPrice(totalPriceCount);
    } catch (error) {
      console.error("Error fetching total price:", error);
    } finally {
      setLoadingPrice(false);
    }
  };
  // Fetch total users on component mount
  useEffect(() => {

    fetchTotalPrice();

    // Inject Google Tag script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16880834463";
    document.head.appendChild(script);

    // Initialize Google Tag
    const scriptTag = document.createElement("script");
    scriptTag.innerHTML = `
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());
       gtag('config', 'AW-16880834463');
     `;
    document.head.appendChild(scriptTag);
  }, []);


  const handleClose2 = () => {
    setOpenModal2(false); // Close the modal

  };

  const handleClaimDiscount = () => {
    window.open("https://billing.stripe.com/p/login/cN2cPd5tvdio5lC8ww", "_blank"); // Open the Stripe login in a new tab

    handleClose2();
  };
  // Function to fetch the total active users
  const fetchTotalUsers = async () => {
    setLoadingUsers(true);
    let totalUsersCount = 0;
    let apps = [];

    const user = auth.currentUser;

    try {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data().liveProjects || [];

        // Calculate total users and breakdown for each app
        userData.forEach((project) => {
          const activeUsers = Number(project.activeUsers) || 0;
          totalUsersCount += activeUsers;
          apps.push({
            name: project.name, // App name
            activeUsers,
            percentage: 0, // We'll calculate percentage next
          });
        });

        // Calculate the percentage breakdown for each app
        apps = apps.map((app) => ({
          ...app,
          percentage: ((app.activeUsers / totalUsersCount) * 100).toFixed(2),
        }));

        // Prepare bar chart data
        const chartData = {
          labels: apps.map((app) => app.name), // App names for X-axis
          datasets: [
            {
              label: "Active Users",
              data: apps.map((app) => app.activeUsers), // Active users per app
              backgroundColor: "#42A5F5", // Customize color
            },
          ],
        };

        // Set state for bar chart and other data
        setBarChartDataDashboard(chartData);
        setAppsData(apps);
        setTotalUsers(totalUsersCount);
      }
    } catch (error) {
      console.error("Error fetching total users:", error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const initializeTutorialTokens = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const userDocRef = doc(db, "users", user.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const updates = {};

      if (!("finished_demo_token" in userData)) updates.finished_demo_token = false;
      if (!("step1passed" in userData)) updates.step1passed = false;
      if (!("step2passed" in userData)) updates.step2passed = false;
      if (!("step3passed" in userData)) updates.step3passed = false;

      if (Object.keys(updates).length > 0) {
        await setDoc(userDocRef, updates, { merge: true });
      }
    }
  };

  // Fetch total users on component mount
  useEffect(() => {
    fetchTotalUsers();
  }, []);

  const [currentStep, setCurrentStep] = useState("0");
  const [isShowingPopup, setIsShowingPopup] = useState(false);

  useEffect(() => {
    const checkAndStartTutorial = async () => {
      const user = auth.currentUser;
      if (!user) return;

      try {
        // Step 1: Check Subscription Status
        const response = await axios.post('https://checksubscription-wkmofyogxa-uc.a.run.app', { email: user.email });
        // If user has an active subscription, exit the function
        if (
          response.data?.products?.includes("prod_RcEJEcO8pMmfKw") ||
          response.data?.products?.includes("prod_RcEJNYY5WtQO4U") ||
          response.data?.products?.includes("prod_R4OZRuMOJn2RXX") ||
          response.data?.products?.includes("prod_R4OaST7dmwUbVR")
        ) {
          console.log("User has an active subscription. Skipping tutorial.");

          return;
        }
      } catch (error) {
        // Step 2: If no response (request failed entirely), assume no subscription & show tutorial
        if (!error.response) {


          console.log("No response from server. Assuming no subscription. Running tutorial...");
        } else {
          console.error("Error checking subscription:", error);
          return; // If there's an error other than no response, don't start the tutorial
        }
      }

      // Step 3: If we reach this point, run the tutorial
      console.log("User has no subscription. Running tutorial...");

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();

        if (!userData.finished_demo_token) {
          // Initialize tutorial tokens
          await initializeTutorialTokens();

          // Start tutorial from first step
          setCurrentStep("0");
          setIsShowingPopup(true);
        }
      }
    };

    checkAndStartTutorial();
  }, []);



  const updateTutorialStep = async (step) => {
    const user = auth.currentUser;
    if (!user) return;

    const userDocRef = doc(db, "users", user.uid);
    await setDoc(userDocRef, { [step]: true }, { merge: true });
  };


  const completeTutorial = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const userDocRef = doc(db, "users", user.uid);
    await setDoc(userDocRef, { finished_demo_token: true }, { merge: true });
  };

  // const handleNextStep = async () => {
  //   if (currentStep === "step1") {
  //     await updateTutorialStep("step1passed");
  //     setCurrentStep("step2");
  //   } else if (currentStep === "step2") {
  //     await updateTutorialStep("step2passed");
  //     setCurrentStep("step3");
  //   } else if (currentStep === "step3") {
  //     await updateTutorialStep("step3passed");
  //     await completeTutorial();
  //     setIsShowingPopup(false);
  //   }
  // };


  const fetchProjects = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data().liveProjects || [];
          const formattedData = userData.map((project, index) => {
            const monthlyRevenueValues = new Array(12).fill(0);
            project.revenueHistory.forEach((entry) => {
              const date = new Date(entry.date);
              const month = date.getMonth();
              const amount = entry.amount;
              if (month === currentMonth) {
                totalCurrentMonthRevenue += amount;
              } else if (month === previousMonth) {
                totalPreviousMonthRevenue += amount;
              }
              monthlyRevenueValues[month] += entry.amount;
            });

            setCurrentMonthRevenue(totalCurrentMonthRevenue);
            setPreviousMonthRevenue(totalPreviousMonthRevenue);
            setPreviousMonthName(currentDate.toLocaleString('default', { month: 'long', year: 'numeric' }).replace(new RegExp(`^${new Date().toLocaleString('default', { month: 'long' })}`), previousMonth));

            return {
              name: project.name || `Project ${index + 1}`,
              data: monthlyRevenueValues,
            };
          });

          setExportableData(formattedData);



        }
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (previousMonthRevenue > 0) {
      const change = ((currentMonthRevenue - previousMonthRevenue) / previousMonthRevenue) * 100;
      setPercentageChange(change);
    } else {
      setPercentageChange(currentMonthRevenue > 0 ? 100 : 0); // Handle case when previous month revenue is 0
    }
  }, [currentMonthRevenue, previousMonthRevenue]);

  // Initial data load
  useEffect(() => {
    fetchProjects();
  }, []); // Runs onl // Empty array, runs only on mount


  const TutorialPopup = ({ open, onClose, step, onNext, onBack, isTracking }) => {
    const steps = [
      <Popup1 />,
      <Popup2 />,
      <Popup3 />,
      <Popup4 />,
      <Popup5 />,
      <Popup6 />,
      <Popup8 />,

    ];

    return (
      <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose} sx={{ backgroundColor: '#ffffff40' }}>
        <div style={{ backgroundColor: 'black' }}>
          <DialogTitle
            style={{
              fontSize: isMobile ? "3vw" : "1.9vw",
              color: 'white',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between' // Space out title & progress
            }}
          >
            CodeGrow Walkthrough

            {/* Progress Bar Container */}
            <div style={{
              width: '35%',
              height: '10px',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '5px',
              overflow: 'hidden',
              marginLeft: '10px'
            }}>
              {/* Progress Fill */}
              <div style={{
                width: `${(step / 6) * 100}%`,  // 7 because steps.length - 1 = 8 - 1
                height: '100%',
                backgroundColor: 'white',
                transition: 'width 0.3s ease'
              }}></div>
            </div>

            {/* Percentage Text */}
            <span style={{ marginLeft: '10px', fontSize: isMobile? "2vw" :'1vw', color: 'white' }}>
              {Math.round((step / 6) * 100)}%
            </span>
          </DialogTitle>
        </div>
        <DialogContent>
          {/* Render either step text or a popup component */}
          {typeof steps[step] === 'string' ? (
            <p>{steps[step]}</p>
          ) : (
            steps[step] // Render the component directly (like Popup1)
          )}
        </DialogContent>
        <DialogActions>
          {step > 0 && (
            <VuiButton color="primary" variant="outlined" onClick={onBack}>
              Back
            </VuiButton>
          )}

          {step < 4 && !isTracking ? (
            <VuiButton color="primary" variant="contained" onClick={onNext}>
              Next
            </VuiButton> // No tracking for steps 1-4
          ) : step <= 4 ? (
            <VuiButton color="primary" variant="contained" onClick={onNext}>
              Next
            </VuiButton> // Start tracking for steps 5 and onward
          ) : step == 6 ? (
            <VuiButton color="primary" variant="contained" onClick={onNext}>
              No thanks
            </VuiButton> // Start tracking for steps 5 and onward
          ) : (
            <VuiButton color="primary" variant="contained" onClick={onNext}>
              Continue with free plan
            </VuiButton> // Custom button for steps > 4
          )}
        </DialogActions>

      </Dialog>
    );
  };

  const Popup1 = () => {
    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <VuiTypography style={{ fontSize: isMobile ? "4vw" : "2.6vw", color: 'black', fontWeight: 'bold', }}>  Welcome To <bold style={{ color: '#FD3837', }}>CodeGrow</bold> </VuiTypography>
          <br />
          <VuiTypography style={{ fontSize: isMobile ? "3.4vw" : "1.6vw", color: 'black', fontWeight: 'bold', marginBottom: '15px' }}>Follow this step by step guide to learn how to use Codegrow.</VuiTypography>


        </VuiBox>

        <center>
          <VuiBox style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', width: isMobile? "100%" :'75%', backgroundColor: '#e8e8e8', padding: '20px', borderRadius: '15px' }}>


            <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.4vw", fontWeight: 'bold', color: 'black' }}><li>To find an app idea <bold style={{display: isMobile? "none" : "absolute"}}>in the current highest ranking niche.</bold></li></VuiTypography>

            <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.4vw", fontWeight: 'bold', color: 'black' }}><li>To plan and design the app.</li></VuiTypography>

            <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.4vw", fontWeight: 'bold', color: 'black' }}><li>To fully code and test the app.</li></VuiTypography>

            <VuiTypography style={{ fontSize: isMobile ?"2.7vw": "1.4vw", fontWeight: 'bold', color: 'black' }}><li>To create all App Store Assets and release.</li></VuiTypography>



          </VuiBox>
        </center>

      </div>

    );
  };

  const Popup2 = () => {
    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <VuiTypography style={{ fontSize: isMobile ? "4vw" : "2.6vw", color: 'black', fontWeight: 'bold', }}> Find An <bold style={{ color: '#FD3837', }}>App Idea</bold> </VuiTypography>

          <br />

          <center>
            <VuiBox style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%', backgroundColor: '#e8e8e8', padding: '14px', borderRadius: '15px', gap: '20px' }}>

              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click the <IoRocketSharp color="#FD3837" />  Button</li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>Create AI Project</bold></li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Choose your perfect <bold style={{ color: '#FD3837', }}>app idea</bold></li></VuiTypography>

            </VuiBox>
          </center>

        </VuiBox>



      </div>

    );
  };

  const Popup3 = () => {
    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <VuiTypography style={{ fontSize: isMobile ? "4vw" : "2.6vw", color: 'black', fontWeight: 'bold', }}> <bold style={{ color: '#FD3837', }}>Plan</bold> & <bold style={{ color: '#FD3837', }}>Design</bold> </VuiTypography>

          <br />

          <center>
            <VuiBox style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%', backgroundColor: '#e8e8e8', padding: '14px', borderRadius: '15px', gap: '20px' }}>

              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>Choose Idea</bold></li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Review you <bold style={{ color: '#FD3837', }}>app breakdown</bold></li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>Save Project</bold></li></VuiTypography>

            </VuiBox>
          </center>

        </VuiBox>



      </div>

    );
  };

  const Popup4 = () => {
    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <VuiTypography style={{ fontSize: isMobile ? "4vw" : "2.6vw", color: 'black', fontWeight: 'bold', }}> <bold style={{ color: '#FD3837', }}>Code</bold> Your App </VuiTypography>

          <br />

          <center>
            <VuiBox style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%', backgroundColor: '#e8e8e8', padding: '14px', borderRadius: '15px', gap: '20px' }}>

              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>AI Code</bold></li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>Select Project</bold> & choose your project</li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Fill in your <bold style={{ color: '#FD3837', }}>custom app requirements </bold></li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>Start Ai Coding</bold></li></VuiTypography>


            </VuiBox>
          </center>

        </VuiBox>



      </div>

    );
  };

  const Popup5 = () => {
    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <VuiTypography style={{ fontSize: isMobile ? "4vw" : "2.6vw", color: 'black', fontWeight: 'bold', }}> <bold style={{ color: '#FD3837', }}>Expand</bold> Your App </VuiTypography>

          <br />

          <center>
            <VuiBox style={{ display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%', backgroundColor: '#e8e8e8', padding: '14px', borderRadius: '15px', gap: '20px' }}>

              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>AI Code Expander</bold></li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Fill in your <bold style={{ color: '#FD3837', }}>new requirements</bold></li></VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}><li>Click <bold style={{ color: '#FD3837', }}>Start Ai Expander</bold></li></VuiTypography>

            </VuiBox>
          </center>

        </VuiBox>



      </div>

    );
  };

  const Popup6 = () => {
    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <VuiTypography style={{ fontSize: isMobile ? "4vw" : "2.6vw", color: 'black', fontWeight: 'bold', }}> <bold style={{ color: '#FD3837', }}>Choose Your</bold> Plan </VuiTypography>

          <br />

          <center>
            <div style={{ width: '50vw' }}>
              <stripe-pricing-table
                pricing-table-id="prctbl_1QCFqJCppa4dQE4DSmqN01ky"
                publishable-key="pk_live_51OF11JCppa4dQE4D6Rd7qb0NkEasCjQ512jiF5fAvvQQQ7AH0epTzFJyEvtzoEDT9Hm3UO3uupDzPxbh0ncNU0qq003Cw8fHdd">
              </stripe-pricing-table>
            </div>

          </center>

        </VuiBox>



      </div>

    );
  };


  const Popup7 = () => {
    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <VuiTypography style={{ fontSize: isMobile ? "2vw" : "2.6vw", color: 'black', fontWeight: 'bold', }}> Are You <bold style={{ color: '#FD3837', }}>Sure?</bold>  </VuiTypography>
          <VuiTypography style={{ fontSize: isMobile ? "2vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}>Some offers we thought you would prefer</VuiTypography>

          <br />

          <center>
            <VuiBox style={{ display: 'flex', alignItems: 'start', flexDirection: 'row', width: '100%', backgroundColor: '#e8e8e8', padding: '14px', borderRadius: '15px', gap: '20px' }}>

              <VuiButton
                variant="contained"
                color="primary"
                style={{
                  fontSize: isMobile ? "2vw" : "1.6vw",
                }}
              >
                Get a 10% Discount
              </VuiButton>

              {/* <VuiButton
                variant="contained"
                color="primary"
                style={{
                  fontSize: isMobile ? "2vw" : "1.6vw",
                }}
              >
                Get a 7 Day Free Trial
              </VuiButton> */}


            </VuiBox>
          </center>

        </VuiBox>



      </div>

    );
  };

  const Popup8 = () => {
    const [showDiscount, setShowDiscount] = useState(false); // State to toggle content

    const handleShowDiscount = () => {
      setShowDiscount(true);
    };

    return (
      <div>
        <VuiBox style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          {!showDiscount ? (
            // Initial Offer Section
            <>
              <VuiTypography style={{ fontSize: isMobile ? "4vw" : "2.6vw", color: 'black', fontWeight: 'bold' }}>
                Are You <bold style={{ color: '#FD3837' }}>Sure?</bold>
              </VuiTypography>
              <VuiTypography style={{ fontSize: isMobile ? "3.4vw" : "1.6vw", color: 'black', fontWeight: 'bold' }}>
                Some offers we thought you would prefer
              </VuiTypography>
              <br />
              <center>
                <VuiBox style={{ display: 'flex', alignItems: 'start', flexDirection: 'row', width: '100%', backgroundColor: '#e8e8e8', padding: '0px', borderRadius: '15px', gap: '20px' }}>
                  <VuiButton
                    onClick={handleShowDiscount} // Click to reveal discount
                    variant="contained"
                    color="primary"
                    style={{ fontSize: isMobile ? "2.7vw" : "1.6vw", width: isMobile? "100%" : '20vw' }}
                  >
                    Get a 20% Discount
                  </VuiButton>
                  {/* <VuiButton
                    variant="contained"
                    color="primary"
                    style={{ fontSize: isMobile ? "2vw" : "1.6vw" }}
                  >
                    Get a 7 Day Free Trial
                  </VuiButton> */}
                </VuiBox>
              </center>
            </>
          ) : (
            // Discount Code Section
            <>
              <VuiTypography style={{ fontSize: isMobile ? "4.7vw" : "2.6vw", color: 'black', fontWeight: 'bold' }}>
                Get your <bold style={{ color: '#FD3837' }}>20% Discount</bold>
              </VuiTypography>
              <br />
              <VuiBox display="flex" flexDirection="column" gap="20px" mb="20px">
                <VuiBox>
                  <br />
                  <VuiTypography variant="body1" color="black" mb="16px">
                    Enjoy a 20% discount code on us, valid for 24 hours.
                  </VuiTypography>
                </VuiBox>
                <VuiBox display="flex" alignItems="center" >
                  <VuiInput value="CODEGROW20" disabled style={{ marginRight: '8px' }} />
                  <IconButton onClick={handleCopy} color="primary">
  {copied ? <CheckCircle /> : <ContentCopy />}
</IconButton>
                </VuiBox>
              </VuiBox>
              <div style={{ width: '50vw' }}>
                <stripe-pricing-table
                  pricing-table-id="prctbl_1QCFqJCppa4dQE4DSmqN01ky"
                  publishable-key="pk_live_51OF11JCppa4dQE4D6Rd7qb0NkEasCjQ512jiF5fAvvQQQ7AH0epTzFJyEvtzoEDT9Hm3UO3uupDzPxbh0ncNU0qq003Cw8fHdd">
                </stripe-pricing-table>
              </div>


            </>
          )}
        </VuiBox>
      </div>
    );
  };


  const [isTracking, setIsTracking] = useState(false);

  const handleNextStep = async () => {
    if (currentStep === "0") {
      await updateTutorialStep("step1passed");
      setCurrentStep("1");
    } else if (currentStep === "1") {
      await updateTutorialStep("step2passed");
      setCurrentStep("2");
    } else if (currentStep === "2") {
      await updateTutorialStep("step3passed");
      setCurrentStep("3");
    } else if (currentStep === "3") {
      await updateTutorialStep("step4passed");
      setCurrentStep("4"); // Start tracking
      // setIsTracking(true); // Start tracking once the user enters step 5
    } else if (currentStep === "4") {
      await updateTutorialStep("step5passed");
      setCurrentStep("5");
    } else if (currentStep === "5") {
      await updateTutorialStep("step6passed");
      setCurrentStep("6");
    } else if (currentStep === "6") {
      await updateTutorialStep("step7passed");
      await completeTutorial();
      setIsShowingPopup(false); // End of the tutorial
    }
  };

  const handleBackStep = () => {
    if (currentStep === "0") {
      setCurrentStep("step1");
    } else if (currentStep === "1") {
      setCurrentStep("0");
    } else if (currentStep === "2") {
      setCurrentStep("1");
    } else if (currentStep === "3") {
      setCurrentStep("2");
    } else if (currentStep === "4") {
      setCurrentStep("3");
    } else if (currentStep === "5") {
      setCurrentStep("4");
    } else if (currentStep === "6") {
      setCurrentStep("5");
    }

  };

  return (
    <FreeLayout>
      <DashboardNavbar />

      <VuiBox py={3}>
        <div>
          {isShowingPopup && (
            <div className="popup" style={{ width: '100vw' }}>
              <TutorialPopup
                open={isShowingPopup}
                onClose={() => setIsShowingPopup(false)}
                step={currentStep}
                onNext={handleNextStep}
                onBack={handleBackStep}
                isTracking={isTracking}
              // style={{width:'100vw'}}
              />
            </div>
          )}

        </div>
        {showPopup && (
          <Dialog open={openModal2} onClose={handleClose2} maxWidth="md" fullWidth>
            <DialogTitle style={{ color: 'black', backgroundColor: 'white' }} >
              <VuiTypography variant="h4" color="black" fontWeight="medium">
                Welcome to CodeGrow!
              </VuiTypography>
              <VuiTypography variant="h6" color="black" fontWeight="medium">
                Great to have you here!
              </VuiTypography>
            </DialogTitle>
            <DialogContent className="custom-scrollbar" style={{ color: 'white', backgroundColor: 'black', }}>
              <VuiBox display="flex" flexDirection="column" gap="20px" mb="20px">
                <VuiBox>
                  <br />
                  <VuiTypography variant="body1" color="white" mb="16px">
                    Enjoy a 10% discount code on us, valid for 24 hours.
                  </VuiTypography>
                </VuiBox>

                <VuiBox display="flex" alignItems="center" >
                  {/* <VuiTypography variant="button" color="white" fontWeight="medium" mb="8px">
           Discount Code
         </VuiTypography> */}
                  <VuiInput
                    value="CODEGROW10"
                    disabled

                    style={{ marginRight: '8px' }}
                  />
                  <IconButton onClick={handleCopy} color={buttonColor}>
                    <ContentCopy />
                  </IconButton>
                </VuiBox>
              </VuiBox>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: 'white', }}>
              <VuiButton onClick={handleClose2} color="primary">
                Close
              </VuiButton>
              <VuiButton onClick={handleClaimDiscount} style={{ backgroundColor: '	#008000' }}>
                Claim Discount
              </VuiButton>
            </DialogActions>
          </Dialog>
        )}

        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "This Month's Revenue", fontWeight: "regular" }}
                count={"$" + currentMonthRevenue}
                percentage={{
                  color: percentageChange >= 0 ? "success" : "error",
                  text: (
                    <Typography
                      variant="caption"
                      style={{ fontSize: "0.8rem", verticalAlign: "middle" }}
                    >
                      {`${percentageChange >= 0 ? '+' : '-'}${Math.abs(percentageChange).toFixed(2)}%`}
                    </Typography>
                  ),
                }}
                icon={{
                  color: "info",
                  component: <IoWallet color="white" size="22px" style={{ marginBottom: '10px' }} />,
                }}
              />

            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Last Month's Revenue", fontWeight: "regular" }}
                count={"$" + previousMonthRevenue}
                percentage={{
                  color: (percentageChange * -1) >= 0 ? "success" : "error",
                  text: (
                    <span>
                      <Typography
                        variant="caption"
                        style={{ fontSize: "0.8rem", verticalAlign: "middle" }}
                      >
                        {`${(percentageChange * -1) >= 0 ? '+' : '-'}${Math.abs(percentageChange).toFixed(2)}%`}
                      </Typography>
                    </span>
                  ),
                }}
                icon={{
                  color: "info",
                  component: (
                    <IoWallet size="22px" style={{ marginBottom: "10px" }} color="white" />
                  ),
                }}
              />
            </Grid>


            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Users" }}
                count={loadingUsers ? "Loading..." : totalUsers}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: <IoGlobe size="22px" style={{ marginBottom: '10px' }} color="white" /> }}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Potential Sale Revenue" }}
                count={loadingUsers ? "Loading..." : "$" + totalPrice}
                // percentage={{ color: "success", text: "+5%" }}
                icon={{ color: "info", component: <IoListCircleSharp size="22px" style={{ marginBottom: '10px' }} color="white" /> }}
              />
            </Grid>



          </Grid>
        </VuiBox>

        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <WelcomeMark />
            </Grid>

          </Grid>
        </VuiBox>




        {/* row 1 */}
        <VuiBox mb={3}>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="stretch">

            <Grid item xs={12} md={6} lg={6}>
              <Card style={{ minHeight: '100%', }}>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiBox display="flex" alignItems="start" direction="row" justifyContent="space-between" mb={3}>
                    <div>
                      <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px" sx={{ marginRight: '16px' }}>
                        Sales Overview
                      </VuiTypography>
                      <VuiBox display="flex" alignItems="center" mb="40px">
                        <VuiTypography variant="button" color={percentageChange >= 0 ? "success" : "error"} fontWeight="bold">
                          {percentageChange.toFixed(2)}% {percentageChange >= 0 ? "more" : "less"} than {previousMonthName}

                        </VuiTypography>
                      </VuiBox>
                    </div>

                    <Button
                      style={{ color: 'white' }}
                      variant="contained"
                      color="primary"
                      onClick={fetchProjects} // This triggers the data fetch
                    >
                      Refresh Data
                    </Button>
                  </VuiBox>


                  <VuiBox sx={{ height: "310px" }}>
                    {loading ? (
                      <VuiTypography variant="button" color="text" fontWeight="medium">
                        Loading data...
                      </VuiTypography>
                    ) : (
                      <LineChart
                        lineChartData={exportableData.length > 0 ? exportableData : [{ name: "No Data", data: [0] }]}
                        lineChartOptions={lineChartOptionsDashboard}
                      />
                    )}
                  </VuiBox>

                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Projects />
            </Grid>

          </Grid>
        </VuiBox>
        {/* row 1 end */}

        {/* row 2 */}
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <OrderOverview />
            </Grid>


            <Grid item xs={12} md={6} lg={6}>
              <Card style={{ minHeight: '450px', maxHeight: '450px', overflowY: 'auto' }}>
                <VuiBox>
                  {/* Header - left-aligned */}
                  <VuiTypography style={{ fontSize: '23px' }} color="white" fontWeight="bold" mb="5px">
                    Active Users
                  </VuiTypography>

                  {/* Table Structure */}
                  <table style={{ width: '100%', color: 'white', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ borderBottom: '1px solid #818589' }}>
                        <th style={{ textAlign: 'left', padding: '10px', fontSize: '18px' }}>App Name</th>
                        <th style={{ textAlign: 'center', padding: '10px', fontSize: '18px' }}>Active Users</th>
                        <th style={{ textAlign: 'center', padding: '10px', fontSize: '18px' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appsData.map((app) => (
                        <tr key={app.name} style={{ borderBottom: '1px solid #818589' }}>
                          <td style={{ padding: '10px', textAlign: 'left', width: '40%' }}>
                            <VuiTypography style={{ color: 'white' }} variant="button" fontWeight="medium">
                              {app.name}
                            </VuiTypography>
                          </td>
                          <td style={{ padding: '10px', textAlign: 'center', width: '30%' }}>
                            <VuiTypography style={{ color: 'white' }} variant="lg" fontWeight="bold">
                              {app.activeUsers}
                            </VuiTypography>
                          </td>
                          <td style={{ padding: '10px', textAlign: 'center', width: '30%' }}>
                            <VuiProgress value={app.percentage} color="info" sx={{ background: "#2D2E5F" }} />

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </VuiBox>
              </Card>
            </Grid>

          </Grid>
        </VuiBox>
        {/* row 2 end */}

        {/* row 3 */}
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <SatisfactionRate />
            </Grid>
            {/* <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking />
            </Grid> */}
          </Grid>
        </VuiBox>
        {/* row 3 end */}

      </VuiBox>
      <Footer />
    </FreeLayout>
  );
}

export default Dashboard;
