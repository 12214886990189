import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Vision UI Dashboard React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Vision UI Dashboard React routes
import routes from "routes";

// Vision UI Dashboard React contexts
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { IoVideocam } from "react-icons/io5";
import { IoCloseCircle } from "react-icons/io5";
import { FaWindowClose } from "react-icons/fa";

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [openModal, setOpenModal] = useState(false); // State for modal

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route path={route.route} element={<route.component />} key={route.key} />;
      }

      return null;
    });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // const configsButton = (
  //   // <VuiBox
  //   //   display="flex"
  //   //   justifyContent="center"
  //   //   alignItems="center"
  //   //   width="3.5rem"
  //   //   height="3.5rem"
  //   //   bgColor="info"
  //   //   shadow="sm"
  //   //   borderRadius="50%"
  //   //   position="fixed"
  //   //   right="2rem"
  //   //   bottom="2rem"
  //   //   zIndex={99}
  //   //   color="white"
  //   //   sx={{ cursor: "pointer" }}
  //   //   onClick={handleOpenModal} // Open modal on click
  //   // >
  //   //   {/* <IoVideocam /> */}
  //   // </VuiBox>
  // );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand=""
              brandName="CodeGrow"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/Home" />} />
        </Routes>
        {/* Modal for YouTube Video */}
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: { backgroundColor: '#333', color: 'white' }, // Customize modal background color
          }}
        >
          <DialogTitle style={{ color: 'white' }}>Watch the Video</DialogTitle>
          <DialogContent>
            <Button onClick={handleCloseModal} style={{ float: 'right', color: 'white' }}>
              <FaWindowClose />

            </Button>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/YOUR_VIDEO_ID" // Replace with your video ID
                title="YouTube Video"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand=""
            brandName="CodeGrow"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/Home" />} />
        </Routes>
      {/* Modal for YouTube Video */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="80vw" // Adjust the width as needed
        fullWidth
        PaperProps={{
          style: { backgroundColor: '#333', color: 'white', height: '80vh' }, // Customize modal background color
        }}
      >
        <DialogTitle style={{ color: 'white' }}>
          Watch the guide video
          <Button onClick={handleCloseModal} style={{ backgroundColor: 'blue', float: 'right', color: 'white' }}>
            <IoCloseCircle style={{ backgroundColor: 'blue' }} />

          </Button>
        </DialogTitle>
        <DialogContent style={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(80vh - 64px)' }}> {/* Subtracting DialogTitle height */}
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/LBF_VrXWwXQ" // Replace with your video ID
            title="YouTube Video"
            frameBorder="0"
            allowFullScreen
            style={{ border: 'none' }} // Remove iframe border
          />
        </DialogContent>
      </Dialog>

    </ThemeProvider>
  );
}
