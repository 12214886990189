import React, { useState, useEffect } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(getTimeUntilNextFriday());
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(getTimeUntilNextFriday());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getTimeUntilNextFriday() {
    let now = new Date();
    let nextFriday = new Date();

    // Find next Friday
    nextFriday.setUTCDate(now.getUTCDate() + ((5 - now.getUTCDay() + 7) % 7));
    nextFriday.setUTCHours(19, 0, 0, 0); // Set to 7 PM GMT

    // If today is Friday and past 7 PM, move to next week
    if (now > nextFriday) {
      nextFriday.setUTCDate(nextFriday.getUTCDate() + 7);
    }

    let timeLeftMs = nextFriday - now;
    return formatTimeLeft(timeLeftMs);
  }

  function formatTimeLeft(ms) {
    if (ms <= 0) return { days: "00", hours: "00", minutes: "00", seconds: "00" };
    return {
      days: String(Math.floor(ms / (1000 * 60 * 60 * 24))).padStart(2, "0"),
      hours: String(Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0"),
      minutes: String(Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0"),
      seconds: String(Math.floor((ms % (1000 * 60)) / 1000)).padStart(2, "0"),
    };
  }

  return (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      style={{
        padding: "12px",
        borderRadius: "12px",
        marginTop: "10px",
        width: "fit-content",
      }}
      className="pop-in"

    >
      {Object.entries(timeLeft).map(([label, value], index, array) => (
        <React.Fragment key={label}>
          <VuiBox textAlign="center" mx={2}>
            <VuiTypography
              style={{
                backgroundColor: "#ffffff30",
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingLeft: "30px",
                paddingRight: "30px",
                borderRadius:'20px',
                color: "white",
                fontSize: isMobile? "5vw" : "2.8vw",
                fontWeight: "bold",
                marginBottom:'10px'
              }}
            >
              {value}
            </VuiTypography>
            <VuiTypography
              style={{
                color: "white",
                fontSize: isMobile? "3vw" : "1.3vw",
                fontWeight: "medium",
              }}
            >
              {label.toUpperCase()}
            </VuiTypography>
          </VuiBox>
          {index < array.length - 1 && (
            <VuiTypography
              style={{
                color: "white",
                fontSize: "2.5vw",
                fontWeight: "bold",
                margin: "-7px",
              }}
            >
              :
            </VuiTypography>
          )}
        </React.Fragment>
      ))}
    </VuiBox>
  );
};

export default CountdownTimer;