import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { Link, useNavigate } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import CoverLayout from "layouts/authentication/components/BasicLayout";
import borders from "assets/theme/base/borders";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";
import { Grid, Typography } from "@mui/material";
import 'rc-slider/assets/index.css'; // Import the default styles
import { useMediaQuery } from '@mui/material'; // Import useMediaQuery
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // carousel styles
import bgDash4 from "assets/images/bg4.svg"; // Replace with your background image
import longlogoblack from "assets/images/codegrowlong-black.png"; // Replace with your background image
import minicolourbg from "assets/images/minicolourbg.svg";
import macbook from "assets/images/macbook.png"; // Replace with your background image
import { IoMdMenu } from "react-icons/io";
import { Drawer } from "@mui/material"; // To use the Drawer component for the popup
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu, MenuItem } from "@mui/material";
import { TbAffiliateFilled } from "react-icons/tb";
import { FaMoneyBillWave } from "react-icons/fa6";
import RcSlider from 'rc-slider'; // Correct import statement
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import monthly from "assets/images/monthly.svg"; // Replace with your background image
import yearly from "assets/images/yearly.svg"; // Replace with your background image
import growth from "assets/images/growth.svg"; // Replace with your background image
import { FaCoins } from "react-icons/fa";
import { IoIosAppstore } from "react-icons/io";
import { GiArtificialIntelligence } from "react-icons/gi";
import ai1 from "assets/images/ai1.png"; // Replace with your background image
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import imageResizeAndZip from "layouts/iconsize/data/imageResize";


function SignIn() {

  useEffect(() => {
    // Ensure the window object is available before running the animation
    if (typeof window !== "undefined") {
      // GSAP Animation for all elements with .pop-in
      gsap.utils.toArray(".pop-in").forEach((element) => {
        gsap.from(element, {
          opacity: 0,
          y: 50,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: element, // Use the individual element as the trigger
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        });
      });

      // Inject Google Tag Manager script
      const gtagScript = document.createElement("script");
      gtagScript.async = true;
      gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-16880834463";
      document.head.appendChild(gtagScript);

      // Initialize Google Tag
      const gtagInitScript = document.createElement("script");
      gtagInitScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16880834463');
      `;
      document.head.appendChild(gtagInitScript);

    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const [openQuestion, setOpenQuestion] = useState(null);
  const [isYearly, setIsYearly] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const [appsPerMonth, setAppsPerMonth] = useState(2);
  const [dailyRevenue, setDailyRevenue] = useState(10);
  const [flippaSalePrice, setFlippaSalePrice] = useState(1500);
  const monthlyRevenuePerApp = dailyRevenue * 30; // 30 days in a month
  const yearlyRevenuePerApp = monthlyRevenuePerApp * 12;
  const totalRevenuePerMonth = (appsPerMonth * 12) * monthlyRevenuePerApp;
  const totalRevenuePerYear = totalRevenuePerMonth * 12;
  const totalFlippaRevenue = (appsPerMonth * 12) * flippaSalePrice;
  const totalCombinedRevenue = totalRevenuePerYear + totalFlippaRevenue;
  const totalCombinedMonthly = totalCombinedRevenue / 12;
  const [file, setFile] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };


  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 200); // Short delay to prevent accidental closing
  };

  const handleClose2 = () => {
    setTimeout(() => {
      setAnchorEl2(null);
    }, 200); // Short delay to prevent accidental closing
  };


  // Handle burger menu button click
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is included in the CodeGrow subscription?",
      answer:
        "The subscription includes unlimited access to our AI-powered app development tools, fully customizable app templates, video tutorials, pre-built Firebase and AdMob integrations, and more."
    },
    {
      question: "What AI features are included?",
      answer:
        "CodeGrow's AI tools can generate app designs, suggest code improvements, debug issues, and even create fully functional components to save you time and effort."
    },
    {
      question: "How many apps can I create with CodeGrow?",
      answer:
        "You can create an unlimited number of apps with your subscription. There's no cap on creativity or output!"
    },
    {
      question: "What programming technologies are used in these apps?",
      answer:
        "CodeGrow apps are built with Flutter for cross-platform compatibility and leverage Firebase for back-end services. SQLite is also available for local storage."
    },
    {
      question: "Are there templates available for specific types of apps?",
      answer:
        "Yes, CodeGrow provides a variety of templates for apps such as games, productivity tools, social media integrations, and more. All templates are fully customizable."
    },
    {
      question: "Can I access CodeGrow on multiple devices?",
      answer:
        "Absolutely! CodeGrow is fully responsive and works seamlessly on desktops, tablets, and smartphones, allowing you to build apps from anywhere."
    },
    {
      question: "How do I get started with CodeGrow?",
      answer:
        "Once you sign up, you'll receive immediate access to the platform. Start by exploring the tutorials and templates, and you'll be creating your first app in no time."
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, you can cancel your subscription anytime by logging into your account and navigating to the billing section."
    },
    {
      question: "Does CodeGrow offer real-time support?",
      answer:
        "Yes, our support team is available to assist you with any questions or issues you may encounter while using CodeGrow."
    }
  ];

  function navigateToVideoSection() {
    const element = document.getElementById("demoSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  function navigateToSignIn() {
    // TikTok PII Identification (if applicable)
    ttq.identify({
      "email": "<hashed_email_address>", // Replace with SHA-256 hashed email
      "phone_number": "<hashed_phone_number>", // Replace with SHA-256 hashed phone number
      "external_id": "<hashed_external_id>" // Replace with SHA-256 hashed external ID
    });

    // TikTok Event Tracking
    ttq.track('ViewContent', {
      page_section: "Sign-In Page"
    });

    // Navigation logic
    navigate("/authentication/sign-up");
  }

  function navigateToSignIn2() {
    // TikTok PII Identification (if applicable)
    ttq.identify({
      "email": "<hashed_email_address>", // Replace with SHA-256 hashed email
      "phone_number": "<hashed_phone_number>", // Replace with SHA-256 hashed phone number
      "external_id": "<hashed_external_id>" // Replace with SHA-256 hashed external ID
    });

    // TikTok Event Tracking
    ttq.track('ViewContent', {
      page_section: "Sign-In Page"
    });

    // Navigation logic
    navigate("/authentication/sign-in");
  }

  function navigateToPricing() {
    navigate("/pricingPage");
  }

  function navigateToHome() {
    navigate("/home");
  }

  function navigateToCalculator() {
    navigate("/income-calculator");
  }

  function navigateToAffiliate() {
    navigate("/affiliate-page");
  }

  function navigateToIcons() {
    navigate("/app-store-icon-size-generator");
  }
  function navigateToIdea() {
    navigate("/ai-app-idea-breakdown-tool");
  }
  function navigateToProducts() {
    navigate("/products-page");
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleGenerateIcons = async () => {
    if (file) {
      console.log("Generating icon sizes...");
      try {
        const zipFile = await imageResizeAndZip(file);
        console.log("Icon sizes generated successfully:", zipFile);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(zipFile);
        link.download = "app-icons.zip";
        link.click();
        URL.revokeObjectURL(link.href); // Cleanup URL
      } catch (error) {
        console.error("Error generating icon sizes:", error);
      }
    } else {
      alert("Please upload an image first.");
    }
  };

  const timeoutRef = useRef(null);

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);

    }, 100); // Delay before closing (300ms) (300ms)
  };
  const handleMouseLeave2 = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen2(false);
    }, 500); // Delay before closing (300ms) (300ms)
  };
  return (
    <><CoverLayout >

      {/* top section start */}
      <VuiBox
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: "white",
          minHeight: "100vh",
          marginBottom: "-20px",
          backgroundImage: `url(${bgDash4})`, // Background image for the landing page
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "0",
        }}
      >
        {/* Wrapper for the images and SVG */}
        <VuiBox
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between", // Push images to the sides
            alignItems: "center",
            position: "relative", // Allows absolute positioning inside
            padding: "0 50px", // Adjust space from edges
          }}
        >




        </VuiBox>


        {/* top menu desktop*/}
        <VuiBox
          display={isMobile ? "none" : "flex"}
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "95%"}
          // maxWidth="800px"
          marginTop="0px"
          padding="20px"

          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              onClick={() => navigateToHome()}


              src={longlogoblack} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "17vw" : "6.5vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          <VuiBox display="flex" gap="0px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="outline"
              size="medium"
              onClick={() => navigateToProducts()}
              sx={{

                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Products
            </VuiButton>




            <VuiBox
              sx={{
                position: "relative",
                display: "inline-block",
              }}
              onMouseEnter={() => {setIsOpen(true), setIsOpen2(false)}}
            >
              {/* Button */}
              <VuiButton
                variant="outline"
                size="medium"

                sx={{
                  color: "#fff",
                  fontSize: "1.3vw",
                  padding: "10px 20px",
                  borderRadius: "55px",
                  transition: "color 0.3s ease",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#fe3737",
                  },
                }}
              >
                Free Tools <ArrowDropDownIcon />
              </VuiButton>

              {/* Dropdown Menu */}
              {isOpen && (
                <VuiBox
                onMouseLeave={handleMouseLeave}

                  sx={{
                    zIndex: '150',
                    position: "absolute",
                    top: "120%",
                    left: '-20vw',
                    width: "70vw",
                    background: "#1a1a1a",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: '40px'
                  }}
                >
                  {/* Two Buttons on the Left */}
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '12vw', }}>
                    <VuiButton
                      variant="contained"
                      onClick={() => navigateToIcons()}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <IoIosAppstore size={isMobile ? 24 : 80} color="#fe3737" />
                      App Store Icon Size Generator
                    </VuiButton>

                    <VuiButton
                      variant="contained"
                      onClick={() => navigateToIdea()}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <GiArtificialIntelligence size={isMobile ? 24 : 80} color="#fe3737" />
                      AI App Idea & Breakdown Tool
                    </VuiButton>
                  </div>

                  <VuiBox
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="column"
                    alignItems={isMobile ? "start" : "start"}
                    // maxWidth="800px"
                    width={isMobile ? "100%" : "30%"}
                    padding="20px"
                    height="16vw"

                    borderRadius={borders.borderRadius.md}
                    className="pop-in"

                  >


                    <div>


                      <VuiTypography
                        style={{
                          component: "h1",
                          textAlign: "start",
                          fontSize: isMobile ? "6.5vw" : "1.2vw",
                          fontWeight: "bold",
                          color: "white",
                          marginBottom: isMobile ? "10%" : "0px",

                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                        }}
                        className="pop-in"

                      >
                        Find & Create Your Perfect App
                      </VuiTypography>

                    </div>

                    <div>

                      <VuiTypography
                        style={{
                          component: "h2",
                          textAlign: isMobile ? "start" : "start",
                          fontSize: isMobile ? "4.5vw" : "1vw",
                          fontWeight: "bold",
                          color: "#69696a",
                          marginBottom: isMobile ? "10%" : "0px",
                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here

                        }}
                        className="pop-in"

                      >
                        CodeGrow turns your ideas into fully montiesed IOS apps ready to be released to the App Store                      </VuiTypography>



                    </div>

                    <div>
                      <VuiButton
                        aligncontent="start"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={navigateToSignIn2}
                        style={{
                          backgroundColor: 'transparent',
                          border: " 2px solid #FE3737",
                          color: " #FE3737",
                          fontSize: isMobile ? "3.5vw" : "1vw",
                          padding: "10px 20px",
                          borderRadius: '55px',

                        }}
                      >
                        Start For Free
                      </VuiButton>
                    </div>

                  </VuiBox>

                  {/* Image on the Right */}
                  <img
                    src={ai1}
                    alt="Tool Preview"
                    style={{ width: "20vw", borderRadius: "5px" }}
                  />
                </VuiBox>
              )}
            </VuiBox>



            <VuiBox
              sx={{
                position: "relative",
                display: "inline-block",
              }}
              onMouseEnter={() => {setIsOpen2(true), setIsOpen(false)}}
            >
              {/* Button */}
              <VuiButton
                variant="outline"
                size="medium"

                sx={{
                  color: "#fff",
                  fontSize: "1.3vw",
                  padding: "10px 20px",
                  borderRadius: "55px",
                  transition: "color 0.3s ease",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#fe3737",
                  },
                }}
              >
                Resources <ArrowDropDownIcon />
              </VuiButton>

              {/* Dropdown Menu */}
              {isOpen2 && (
                <VuiBox
                onMouseLeave={handleMouseLeave2}

                  sx={{
                    zIndex: '150',
                    position: "absolute",
                    top: "120%",
                    left: '-20vw',
                    width: "70vw",
                    background: "#1a1a1a",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: '40px'
                  }}
                >
                  {/* Two Buttons on the Left */}
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '12vw', }}>
                    <VuiButton
                      variant="contained"
                      onClick={navigateToCalculator}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <FaMoneyBillWave size={isMobile ? 24 : 40} color="#fe3737" />
                      Income Calculator
                    </VuiButton>

                    <VuiButton
                      variant="contained"
                      onClick={navigateToAffiliate}

                      sx={{
                        fontSize: isMobile ? '2vw' : '1.3vw',
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: '10px',
                        width: '20vw',
                        borderRadius: '45px',
                        border: "2px solid white",
                        backgroundColor: "white !important", // Force white bg
                        color: "black !important", // Force black text
                        "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                      }}
                    >
                      <FaCoins size={isMobile ? 24 : 40} color="#fe3737" />

                      Affiliate Program
                    </VuiButton>
                  </div>

                  <VuiBox
                    display="flex"
                    justifyContent="space-evenly"
                    flexDirection="column"
                    alignItems={isMobile ? "start" : "start"}
                    // maxWidth="800px"
                    width={isMobile ? "100%" : "30%"}
                    padding="20px"
                    height="16vw"

                    borderRadius={borders.borderRadius.md}
                    className="pop-in"

                  >


                    <div>


                      <VuiTypography
                        style={{
                          component: "h1",
                          textAlign: "start",
                          fontSize: isMobile ? "6.5vw" : "1.2vw",
                          fontWeight: "bold",
                          color: "white",
                          marginBottom: isMobile ? "10%" : "0px",

                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                        }}
                        className="pop-in"

                      >
                        Find & Create Your Perfect App
                      </VuiTypography>

                    </div>

                    <div>

                      <VuiTypography
                        style={{
                          component: "h2",
                          textAlign: isMobile ? "start" : "start",
                          fontSize: isMobile ? "4.5vw" : "1vw",
                          fontWeight: "bold",
                          color: "#69696a",
                          marginBottom: isMobile ? "10%" : "0px",
                          lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here

                        }}
                        className="pop-in"

                      >
                        CodeGrow turns your ideas into fully montiesed IOS apps ready to be released to the App Store                      </VuiTypography>



                    </div>

                    <div>
                      <VuiButton
                        aligncontent="start"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={navigateToSignIn2}
                        style={{
                          backgroundColor: 'transparent',
                          border: " 2px solid #FE3737",
                          color: " #FE3737",
                          fontSize: isMobile ? "3.5vw" : "1vw",
                          padding: "10px 20px",
                          borderRadius: '55px',

                        }}
                      >
                        Start For Free
                      </VuiButton>
                    </div>

                  </VuiBox>

                  {/* Image on the Right */}
                  <img
                    src={ai1}
                    alt="Tool Preview"
                    style={{ width: "20vw", borderRadius: "5px" }}
                  />
                </VuiBox>
              )}
            </VuiBox>


            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ bgcolor: "white", padding: "0px !important", }}
              MenuListProps={{
                onMouseEnter: () => clearTimeout(),
                onMouseLeave: handleClose
              }}
            >
              <MenuItem
                onClick={navigateToCalculator}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: '10px',

                  border: "2px solid white",
                  backgroundColor: "white !important", // Force white bg
                  color: "black !important", // Force black text
                  "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                }}
              >
                <FaMoneyBillWave size={isMobile ? 24 : 32} color="#fe3737" />
                Income Calculator
              </MenuItem>

              <MenuItem
                onClick={navigateToAffiliate}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: '0px',
                  border: "2px solid white",
                  backgroundColor: "white !important", // Force white bg
                  color: "black !important", // Force black text
                  "&:hover": { backgroundColor: "#f0f0f0 !important" } // Hover effect
                }}
              >
                <TbAffiliateFilled size={isMobile ? 24 : 32} color="#fe3737" />
                Affiliate
              </MenuItem>
            </Menu>


            <VuiButton
              variant="outline"
              size="medium"
              onClick={() => navigateToPricing()}
              sx={{

                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Pricing
            </VuiButton>
          </VuiBox>

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >
            <VuiButton
              variant="outline"
              size="medium"
              onClick={() => navigateToSignIn2()}
              sx={{

                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "color 0.3s ease",
                "&:hover": {
                  color: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Log in
            </VuiButton>

            <VuiButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              sx={{
                backgroundColor: "#404040",
                color: "#fff",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                display: isMobile ? "none" : "block",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#fe3737", // Change this to your desired hover color
                },
              }}
            >
              Get Started
            </VuiButton>
            <VuiButton
              variant="contained"
              size="medium"
              onClick={navigateToVideoSection}
              sx={{
                backgroundColor: "white",
                color: "#fe3737",
                fontSize: isMobile ? "3.5vw" : "1.3vw",
                padding: "10px 20px",
                borderRadius: "55px",
                transition: "background-color 0.3s ease, color 0.3s ease",
                "&:hover": {
                  color: "white !important", // Ensures the text color changes
                  backgroundColor: "#fe3737",
                },
              }}
            >
              See Demo
            </VuiButton>
          </VuiBox>
        </VuiBox>
        {/* top menu desktop end */}

        {/* Top menu mobile */}
        <VuiBox
          display={isMobile ? "flex" : "none"}
          justifyContent="space-between"
          alignItems="center"
          width={isMobile ? "100%" : "95%"}
          marginTop="0px"
          padding="20px"
          borderRadius={borders.borderRadius.md}
        >
          {/* Logo on the Left */}
          <VuiBox display="flex" alignItems="center">
            <img
              onClick={() => navigateToHome()}


              src={longlogoblack} // Remove the `${}`
              alt="Logo"
              style={{ height: isMobile ? "24vw" : "6.5vw", marginBottom: '-20px' }}
            />

          </VuiBox>

          {/* Burger Button */}
          <VuiBox display="flex" flexDirection="column" gap="0px" style={{ marginBottom: '-20px' }}>
            <VuiButton
              variant="outlined"
              onClick={toggleDrawer}
              sx={{
                color: "#fff",
                fontSize: isMobile ? "4vw" : "2vw",
                padding: "10px 20px",
                borderRadius: "10%",
                backgroundColor: "black", // Styling for burger button
                transition: "background-color 0.3s ease",
                border: '0px solid black',
                "&:hover": {
                  backgroundColor: "#fe3737", // Hover effect for burger button
                },
              }}
            >
              <IoMdMenu size="30px" />
            </VuiButton>
          </VuiBox>
        </VuiBox>

        {/* Drawer Popup when Burger Button is clicked */}
        <Drawer
                 anchor="right"
                 open={drawerOpen}
                 onClose={toggleDrawer}
       
                 sx={{
                   width: "30%", // Adjust the width as needed
                   padding: "20px",
                   bgcolor: "black",
                   height: "auto", // Will adjust dynamically based on the buttons height
                   overflowY: "auto",
                 }}
               >
                 {/* Close Button */}
                 {/* Close Button */}
                 <VuiBox
                   display="flex"
                   justifyContent="flex-end"
                   alignItems="center"
                   position="absolute"
                   top="20px"
                   right="20px"
                 >
                   <VuiButton
                     onClick={toggleDrawer}
       
                     sx={{
                       color: "#000", // Black text color
                       backgroundColor: "transparent", // No background
                       fontSize: "2vw",
                       padding: "0",
                       "&:hover": {
                         backgroundColor: "transparent",
                       },
                     }}
                   >
                     ✕
                   </VuiButton>
                 </VuiBox>
       
                 <VuiBox display="flex" flexDirection="column" gap="20px" marginTop="60px" padding="20px" >
                   {/* The buttons inside the popup */}
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToHome()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     Home
                   </VuiButton>
       
                   {/* The buttons inside the popup */}
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToProducts()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     Products
                   </VuiButton>
       
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToIcons()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     App Icon Size Generator
                   </VuiButton>
        <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToIdea()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     AI Developer
                   </VuiButton>
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToCalculator()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     Income Calculator
                   </VuiButton>
       
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToAffiliate()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     Affiliate Program
                   </VuiButton>
       
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToPricing()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     Pricing
                   </VuiButton>
       
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToSignIn()}
                     sx={{
                       backgroundColor: "#fe3737",
                       color: "white",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "#fe3737 !important",
                         backgroundColor: "whites",
                       },
                     }}
                   >
                     Get Started
                   </VuiButton>
       
                   <VuiButton
                     variant="contained"
                     size="medium"
                     onClick={() => navigateToSignIn2()}
                     sx={{
                       backgroundColor: "white",
                       color: "#fe3737",
                       fontSize: "3.5vw",
                       padding: "10px 20px",
                       borderRadius: "55px",
                       transition: "background-color 0.3s ease, color 0.3s ease",
                       "&:hover": {
                         color: "white !important",
                         backgroundColor: "#fe3737",
                       },
                     }}
                   >
                     Log in
                   </VuiButton>
       
       
                 </VuiBox>
               </Drawer>
        {/* End of top menu mobile */}


        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop={isMobile ? "5%" : "0%"}
          style={{ textAlign: "center" }}
          width="80vw"
        >
          <VuiTypography
            style={{
              fontSize: isMobile ? "6.9vw" : "4vw", // Adjusts according to the viewport width
              fontWeight: 'bold',
              color: "white",
              marginTop: isMobile ? "0px" : "40px",
              marginBottom: "40px",
              textAlign: "center", // Centers the text
              component: "h1",
            }}
            className="pop-in"

          >
            Upload Your <b style={{ color: '#FC3837' }}>App Icon</b>
          </VuiTypography>




          <VuiBox style={{ marginTop: '3vw', backgroundColor: 'transparent' }} py={2} display="flex" flexDirection="column" alignItems="center">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{
                width: "45vw",

                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#2D2E5F",
                color: "white",
                cursor: "pointer",
                textAlign: "center",
                border: "2px solid #ffffff",
                marginTop: "15px",
                fontSize:isMobile? "10px": '20px'
              }}
            />
            <Button
              onClick={handleGenerateIcons}
              variant="outlined"
              color="white"
              sx={{
                border: "none",
                component: "button",
                mt: 10,
                fontSize: isMobile? "3.5vw" :'1.7vw',
                color: "white",
                borderRadius: "50px",
                paddingLeft: '35px',
                paddingRight: '35px',
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#115293",
                },
              }}
            >
              Generate Icon Sizes
            </Button>
          </VuiBox>




        </VuiBox>
      </VuiBox>
      {/* top section end */}

      {/* second section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          background: "white", // Fading effect

          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >


        {/* top menu*/}
        <VuiBox
          display="flex"
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "start" : "center"}
          width={isMobile ? "100%" : "97%"}
          // maxWidth="800px"
          marginTop="-70px"
          padding="20px"
          style={{
            // background: "linear-gradient(to top, white 60%, transparent 100%)", // Fading effect
          }}
          borderRadius={borders.borderRadius.md}
        >

          {/* Buttons on the Right */}
          <VuiBox display="flex" gap="20px" style={{ marginBottom: '-20px' }}
          >


            {/* third white bubble*/}
            <VuiBox

              width={isMobile ? "90%" : "100%"}
              marginLeft={isMobile ? '5%' : '0%'}
              marginTop={isMobile ? "20%" : "8%"}
              height={"isMobile ? 100vw : 85vw"}
              padding="40px"
              style={{
                backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

                backgroundSize: "cover", // Ensures the image covers the entire area
                backgroundPosition: "center", // Centers the image
                backgroundRepeat: "no-repeat", // Prevents the image from repeating
              }}
              borderRadius="25px"
            >
              <VuiBox
                display="flex"
                flexDirection="column"

                justifyContent={isMobile ? "center" : "space-between"}
                alignItems="center"
                marginLeft={isMobile ? "0px" : '0%'}
                width={isMobile ? "100%" : "100%"}
                // maxWidth="800px"

                height=" 100%"
                padding="0px"

                borderRadius="25px"
              >


                <VuiTypography
                  style={{
                    component: "h1",
                    textAlign: "center",
                    fontSize: isMobile ? "6.5vw" : "3.8vw",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "15px",
                    lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
                  }}
                  className="pop-in"

                >
                  Interested In Getting Paid By <bold style={{
                    color: "#FE3737",
                  }}> AdMob</bold>
                </VuiTypography>
                <br />

                <VuiTypography
                  style={{
                    component: "h2",
                    textAlign: "center",
                    fontSize: isMobile ? "4.8vw" : "1.6vw",
                    fontWeight: "bold",
                    color: "#a1a1a1",
                    marginBottom: isMobile ? "20px" : "0px",
                    lineHeight: isMobile ? "1.1" : "1.2", // Adjust line spacing here
                  }}
                  className="pop-in"

                >
                  <bold style={{ color: 'white' }}>
                    Join a community of creators revolutionizing app development. With tools designed for speed and simplicity,
                  </bold>
                  <span style={{ color: "#FE3737", marginLeft: "5px" }}>
                    maximize your earning potential and transform ideas into success.
                  </span>
                </VuiTypography>
                <br />

                <VuiButton
                  aligncontent="center"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => navigateToSignIn2()}
                  style={{
                    backgroundColor: '#1079FB',
                    width: isMobile ? "90%" : "40%",
                    color: " white",
                    fontSize: isMobile ? "3.5vw" : "1.5vw",
                    padding: "10px 20px",
                    component: "button",
                    borderRadius: '55px',
                    marginBottom: isMobile ? "20px" : "0px"
                  }}
                >
                  Get Started Today
                </VuiButton>



              </VuiBox>


            </VuiBox>
            {/* third white bubble end */}

          </VuiBox>
        </VuiBox>
        {/* top menu end */}
      </VuiBox>

      {/* second section end */}


      {/* fourth section start */}
      <VuiBox
        style={{
          width: '100vw',
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          backgroundColor: isMobile ? "white" : "#F7F8FB",

          minHeight: "100vh",
          marginTop: '0px',

          padding: "0", // Remove padding to avoid affecting width
        }}
      >




        {/* good*/}
        <VuiBox
          display={"fixed"}

          width={isMobile ? "100%" : "95%"}
          // maxWidth="800px"
          marginTop="5%"

          padding="40px"
          style={{

            // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          borderRadius="25px"
        >
          <VuiBox
            display={"flex"}
            flexDirection="column"

            justifyContent={"space-between"}
            alignItems="center"
            marginLeft={isMobile ? "0px" : '5%'}
            width={isMobile ? "100%" : "90%"}
            // maxWidth="800px"

            height={" 100%"}
            padding="0px"

            borderRadius="25px"
          >


            <VuiTypography
              style={{
                component: "h1",
                textAlign: "center",
                fontSize: isMobile ? "6.5vw" : "5vw",
                fontWeight: "bold",
                color: "black",
                marginTop: "15px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
              className="pop-in"

            >
              How does it all work?

            </VuiTypography>


            <VuiTypography
              style={{
                marginTop: isMobile ? "10%" : "0px",
                component: "h2",
                textAlign: "center",
                fontSize: isMobile ? "5.5vw" : "1.6vw",
                fontWeight: "bold",
                color: "#4B4B4B",
                marginBottom: isMobile ? "6%" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
              className="pop-in"

            >

              Simplest way to code and sell your app.




            </VuiTypography>
            <br />



            <VuiBox
              display={"flex"}
              flexDirection={isMobile ? "column" : "row"}

              justifyContent={"space-evenly"}
              alignItems="center"

              width={isMobile ? "100%" : "90%"}
              // maxWidth="800px"

              height={" 100%"}
              padding="0px"

              borderRadius="25px"
            >

              <VuiBox
                className="pop-in"

                display={"fixed"}

                width={isMobile ? "100%" : "30%"}
                // maxWidth="800px"
                marginTop="5%"

                padding="40px"
                style={{

                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  backgroundSize: "cover", // Ensures the image covers the entire area
                  backgroundPosition: "center", // Centers the image
                  backgroundRepeat: "no-repeat", // Prevents the image from repeating
                }}
                borderRadius="25px"
              >
                <VuiBox
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    padding: isMobile ? "0px" : "0 0px",
                  }}
                >
                  <img
                    src={monthly}
                    alt={isYearly ? "Free Yearly Package" : "Free Monthly Package"}
                    style={{ width: isMobile ? "20vw" : "5vw", height: "auto" }}
                  />
                  <br />
                  <VuiTypography style={{ component: "h2",fontSize: isMobile ? "5.8vw" : "2.3vw", fontWeight: "bold", color: "black" }}>
                    AI Code
                  </VuiTypography>
                  <br />
                  <VuiTypography style={{component: "h3", fontSize: isMobile ? "4.8vw" : "1.2vw", fontWeight: "bold", color: "#4B4B4B", }}>
                    Let CodeGrow’s AI handle the entire development process—planning, generating, and testing your app seamlessly.
                  </VuiTypography>
                </VuiBox>
                <br />

              </VuiBox>

              <VuiBox
                className="pop-in"

                display={"fixed"}

                width={isMobile ? "100%" : "30%"}
                // maxWidth="800px"
                marginTop={isMobile ? "10%" : "5%"}

                padding="40px"
                style={{

                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  backgroundSize: "cover", // Ensures the image covers the entire area
                  backgroundPosition: "center", // Centers the image
                  backgroundRepeat: "no-repeat", // Prevents the image from repeating
                }}
                borderRadius="25px"
              >
                <VuiBox
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    padding: isMobile ? "0px" : "0 0px",
                  }}
                >
                  <img
                    src={yearly}
                    alt={isYearly ? "Free Yearly Package" : "Free Monthly Package"}
                    style={{ width: isMobile ? "20vw" : "5vw", height: "auto" }}
                  />
                  <br />
                  <VuiTypography style={{ component: "h2",fontSize: isMobile ? "5.8vw" : "2.3vw", fontWeight: "bold", color: "black" }}>
                    App Store Lab
                  </VuiTypography>
                  <br />
                  <VuiTypography style={{ component: "h3",fontSize: isMobile ? "4.8vw" : "1.2vw", fontWeight: "bold", color: "#4B4B4B", }}>
                    Use CodeGrow App Store Lab to design app store screenshots, create app icons, and develop ad creatives.
                  </VuiTypography>
                </VuiBox>
                <br />

              </VuiBox>


              <VuiBox
                className="pop-in"

                display={"fixed"}

                width={isMobile ? "100%" : "30%"}
                // maxWidth="800px"
                marginTop={isMobile ? "10%" : "5%"}

                padding="40px"
                style={{

                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  backgroundSize: "cover", // Ensures the image covers the entire area
                  backgroundPosition: "center", // Centers the image
                  backgroundRepeat: "no-repeat", // Prevents the image from repeating
                }}
                borderRadius="25px"
              >
                <VuiBox
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    padding: isMobile ? "0px" : "0 0px",
                  }}
                >
                  <img
                    src={growth}
                    alt={isYearly ? "Free Yearly Package" : "Free Monthly Package"}
                    style={{ width: isMobile ? "20vw" : "5vw", height: "auto" }}
                  />
                  <br />
                  <VuiTypography style={{component: "h2", fontSize: isMobile ? "5.8vw" : "2.3vw", fontWeight: "bold", color: "black" }}>
                    Sell On Flippa
                  </VuiTypography>
                  <br />
                  <VuiTypography style={{ component: "h3",fontSize: isMobile ? "4.8vw" : "1.2vw", fontWeight: "bold", color: "#4B4B4B", }}>
                    List your iOS app on Flippa using CodeGrow’s pitch decks to optimize your sale price and attract the right buyers.                 </VuiTypography>
                </VuiBox>
                <br />

              </VuiBox>


            </VuiBox>
          </VuiBox>
        </VuiBox>
        {/* good end */}




        {/* third white bubble*/}
        <VuiBox

          width={isMobile ? "83%" : "95%"}
          marginLeft={isMobile ? "0%" : '0%'}
          // maxWidth="800px"
          marginTop="5%"
          height={isMobile ? "150vw" : " 75vw"}
          padding="40px"
          style={{
            backgroundImage: `url(${minicolourbg})`, // Background image for the landing page

            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat", // Prevents the image from repeating
          }}
          borderRadius="25px"
        >
          <VuiBox
            display="flex"
            flexDirection="column"

            justifyContent={"space-between"}
            alignItems="center"
            marginLeft={isMobile ? "0px" : '15%'}
            width={isMobile ? "100%" : "70%"}
            // maxWidth="800px"

            height={" 100%"}
            padding="0px"

            borderRadius="25px"
          >


            <VuiTypography
              style={{
                component: "h1",
                textAlign: "center",
                fontSize: isMobile ? "6.5vw" : "5.8vw",
                fontWeight: "bold",
                color: "white",
                marginTop: "15px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
              className="pop-in"

            >
              <bold style={{
                color: "#FE3737",
              }}>               Start For Free Today
              </bold>
            </VuiTypography>


            <VuiTypography
              style={{
                marginTop: isMobile ? "10%" : "15px",
                component: "h2",
                textAlign: "center",
                fontSize: isMobile ? "5.5vw" : "1.6vw",
                fontWeight: "bold",
                color: "white",
                marginBottom: isMobile ? "6%" : "0px",
                lineHeight: isMobile ? "1.1" : "1.2" // Adjust line spacing here
              }}
              className="pop-in"

            >

              Launch your first app project with expert guidance and AI tools.            </VuiTypography>
            <br />

            <VuiButton
              aligncontent="center"
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => navigateToSignIn()}
              style={{
                backgroundColor: '#1079FB',
                component: "button",
                color: " white",
                fontSize: isMobile ? "3.5vw" : "1.5vw",
                padding: "10px 20px",
                borderRadius: '55px',
                marginBottom: isMobile ? "10%" : "0px"

              }}
            >
              Start For Free Today
            </VuiButton>
            <br />
            <img
              src={macbook} // Replace with your GIF URL
              alt="Centered Image"
              style={{
                borderRadius: "15px",
                width: isMobile ? "80vw" : "70vw",
                objectFit: "contain", // Ensures the image scales nicely within the specified width
                marginBottom: "-0%", // Centers the image horizontally
                boxShadow: "0 0 20px 5px rgba(255, 255, 255, 0.7)", // Adds a white glow effect
              }}
              className="pop-in"

            />


          </VuiBox>


        </VuiBox>
        {/* third white bubble end */}



        {/* FAQ */}
        <VuiBox
          style={{
            marginTop: "100px",
            textAlign: "center",
            padding: "40px 20px",


            background: "linear-gradient(to top, #030515 0%, #7f8c8d 70%, #f7f8fb 100%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <VuiTypography
            style={{
              component: "h2",
              fontSize: "48px",
              color: "#FC3837",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
            }}
            className="pop-in"

          >
            <b style={{ fontSize: isMobile ? "5.8vw" : "4.2vw", color: "black" }}>
              Frequently Asked Questions
            </b>
          </VuiTypography>

          {/* FAQ list */}
          <VuiBox
            style={{
              width: isMobile ? "90%" : "80%",
              maxWidth: "1200px",
              marginTop: "30px",
            }}
          >
            {faqData.map((faq, index) => (
              <VuiBox
                key={index}
                style={{
                  marginBottom: "20px",
                  padding: "20px",
                  background: "#1a1a2e",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleQuestion(index)}
              >
                {/* Question */}
                <VuiTypography
                  style={{
                    color: "#FC3837",
                    fontSize: isMobile ? "4vw" : "1.5vw",
                  }}
                >
                  {faq.question}
                </VuiTypography>

                {/* Answer */}
                {openQuestion === index && (
                  <VuiTypography
                    style={{
                      marginTop: "10px",
                      fontSize: isMobile ? "4vw" : "1.2vw",
                      color: "white",
                      transition: "max-height 0.3s ease-in-out",
                      overflow: "hidden",
                    }}
                  >
                    {faq.answer}
                  </VuiTypography>
                )}
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
        {/* triple section end */}


      </VuiBox>
      {/* fourth section end */}



      <br />



    </CoverLayout>
    </>
  );
}

export default SignIn;
