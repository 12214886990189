import React, { useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import minicolourbg from "assets/images/minicolourbg.svg";
import { Icon } from '@mui/material'; // Assuming you're using Material UI icons

import { Code, Cloud, GitHub, Web, Api, Lock } from '@mui/icons-material'; // Importing Material UI icons
import { SiGoogleadmob } from "react-icons/si";
import { IoLogoFirebase } from "react-icons/io5";
import { FaSwift } from "react-icons/fa";
import { SiXcode } from "react-icons/si";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { RiFlutterFill } from "react-icons/ri";

const technologies = [
    {
        name: "Flutter",
        heading: "Not sure how to build an app that works on both iOS and Android?",
        description: "Join our live class to see how Flutter lets you create beautiful, high-performance apps with a single codebase. We'll show you how AI can help speed up development, so you can focus on launching your idea faster.",
        icon: <RiFlutterFill />
    },
    {
        name: "Swift",
        heading: "Want to build high-quality apps for iPhones and iPads but don’t know where to start?",
        description: "In our live class, we’ll show you how Swift makes iOS development simple and efficient. Plus, you'll learn how AI can generate and debug Swift code for you, making app development easier than ever.",
        icon: <FaSwift />
    },
    {
        name: "Xcode",
        heading: "Confused about how to set up your iOS development environment?",
        description: "Join our live class to learn how Xcode streamlines app development for Apple devices. We'll show you how AI can help with debugging, testing, and optimizing your app – so you can focus on building something amazing.",
        icon: <SiXcode />
    },
    {
        name: "GitHub",
        heading: "Worried about losing your code or breaking something while building?",
        description: "In our live class, learn how GitHub keeps your code safe and makes it easy to undo mistakes. We'll show you how AI helps manage your code versions – no complex commands needed!",
        icon: <GitHub />
    },
    {
        name: "AdMob",
        heading: "Not sure how to make money from your app?",
        description: "Join our live class to learn how AdMob helps you monetize your app with high-quality ads. We’ll also show you how AI can optimize ad placements to maximize your earnings without ruining the user experience.",
        icon: <SiGoogleadmob />
    },
    {
        name: "Firebase",
        heading: "Not sure how to handle user accounts and store data securely?",
        description: "In our live class, we’ll show you how Firebase simplifies authentication, real-time databases, and cloud storage. You’ll also learn how AI can automate backend tasks so you can focus on your app’s features.",
        icon: <IoLogoFirebase />
    },
    {
        name: "Flippa",
        heading: "Want to turn your app into a business and sell it for a profit?",
        description: "Join our live class to see how Flippa helps you buy and sell apps, websites, and online businesses. We'll show you how AI can help analyze app value and spot profitable opportunities.",
        icon: <FaMoneyBillTrendUp />
    },
];

const ThirdWhiteBubble = ({ isMobile }) => {
    const [selectedTech, setSelectedTech] = useState(technologies[0]);

    return (
        <VuiBox
            width={isMobile ? "90%" : "100%"}
            marginLeft={isMobile ? "0%" : "0%"}
            marginTop={isMobile ? "5%" : "8%"}
            padding="40px"
            style={{
                backgroundImage: `url(${minicolourbg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }}
            borderRadius="25px"
            className="pop-in"
        >
            <VuiBox display="flex" flexDirection="column" alignItems="center">
                <VuiTypography
                    style={{
                        textAlign: "center",
                        fontSize: isMobile ? "6.5vw" : "3.8vw",
                        fontWeight: "bold",
                        color: "white",
                        marginBottom: "20px",
                    }}
                >
                    What you'll be <span style={{ color: "#FE3737" }}>building</span> with
                </VuiTypography>

                <VuiBox display="flex" flexWrap="wrap" justifyContent="center" gap="12px" marginBottom="20px">
                    {technologies.map((tech) => (
                        <VuiButton
                            key={tech.name}
                            onClick={() => setSelectedTech(tech)}
                            style={{
                                backgroundColor: selectedTech.name === tech.name ? "#1079FB" : "white",
                                color: selectedTech.name === tech.name ? "white" : "black",
                                fontSize: isMobile ? "3.5vw" : "1.5vw",
                                padding: "25px 35px",
                                borderRadius: "12px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                minWidth: isMobile? "45%" : "110px",
                                minHeight: "110px",
                            }}
                        >
                            <VuiBox marginBottom="5px">

                                <Icon
                                    sx={{
                                        transform: "scale(2)", // Adjust the scale factor as needed
                                        color: selectedTech.name === tech.name ? "#fff" : "#000",
                                        verticalAlign: "middle",
                                        height:'100%'
                                    }}
                                >
                                    {tech.icon}
                                </Icon>
                            </VuiBox>
                            {tech.name}
                        </VuiButton>
                    ))}
                </VuiBox>
                <br />
                {/* Explanation Box */}
                <VuiBox
                    style={{
                        backgroundColor: "#00000060",
                        padding: "0px",
                        borderRadius: "15px",
                        textAlign: "center",
                        width: isMobile? "100%": "90%",
                        border: "1px solid white",
                    }}
                >
                    {/* Top Bar */}
                    <VuiBox
                        style={{
                            backgroundColor: "white",
                            height: "40px",
                            width: "100%",
                            borderTopLeftRadius: "13px",
                            borderTopRightRadius: "13px",
                        }}
                    ></VuiBox>

                    <VuiTypography
                        style={{
                            color: "white",
                            fontSize: isMobile ? "4.5vw" : "2vw",
                            fontWeight: "bold",
                            margin: "30px",
                        }}
                    >
                        {selectedTech.heading}
                    </VuiTypography>

                    <VuiTypography
                        style={{
                            color: "white",
                            fontSize: isMobile ? "4vw" : "1.8vw",
                            fontWeight: "normal",
                            margin: "30px",
                        }}
                    >
                        {selectedTech.description}
                    </VuiTypography>
                </VuiBox>
            </VuiBox>
        </VuiBox>
    );
};

export default ThirdWhiteBubble;